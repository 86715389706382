import React from "react";
import classNames from "classnames/bind";
import * as style from "./HeroBanner.module.scss";
import Button from "../../atoms/Button";
import Text from "../../atoms/Typography";
import Flexbox from "../../atoms/Flexbox";

const cx = classNames.bind(style);


const HeroBanner = ({ handleBooking }) => {
  return (
    <div className={cx("widget", "widget-hero-banner", "without-background")} >
      <Text styleguide="heading1" align="center" styleguideSm="heading2-sm" color="white" mb="lg" fontWeight="bold">
        Run Your Website Business "Profitably"
      </Text>
      <Text styleguide="body1" styleguideSm="body2-sm" color="white" align="center" mb="xl">
        ShopDeck makes selling via your website as easy as selling on
        Amazon, Flipkart, Meesho.
      </Text>
      <Flexbox direction="row" justify="center">
        <Button size="md" appearance="secondary" onClick={handleBooking}>
          Book a free Demo
        </Button>
      </Flexbox>
    </div>
  )
}
export default HeroBanner;
