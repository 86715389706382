import React from "react";
import * as styles from "./Profitability.module.scss";
import classNames from "classnames/bind";
import Button from "../../atoms/Button";
import Text from "../../atoms/Typography";

const cx = classNames.bind(styles);

const Profitability = ({ handleBooking }) => {
  return (
    <div className={cx("widget", "widget-profitability")}>
      <div className={cx("profitability-content-container")}>
        <Text styleguide="heading1" styleguideSm="heading3-sm" mb="md">
          Your <Text styleguide="heading1" styleguideSm="heading3-sm" as="span" color="green">Profitability</Text> is what
          matters to us most
        </Text>
        <Text styleguide="body1" styleguideSm="body2-sm" color="gray" mb="xl">
          All actions geared towards making you profitable
        </Text>
        <Button appearance="tertiary" size="md" className={cx("mb-xxl")} onClick={handleBooking}>
          Book a free Demo
        </Button>
      </div>
      <div className={cx("profitability-feature-container")}>
        <div className={cx("profitability-feature-container-item", "mb-xl")}>
          <div className={cx("icon-container", "graph-icon")}></div>
          <Text styleguide="heading5">
            30+ features to improve your profitability by maximising marketing
            ROI and Minimising RTOs
          </Text>
        </div>
        <div className={cx("profitability-feature-container-item", "mb-xl")}>
          <div className={cx("icon-container", "hand-icon")}></div>
          <Text styleguide="heading5">
            Dedicated growth consultant to help you achieve profitable growth.
          </Text>
        </div>
        <div className={cx("profitability-feature-container-item", "mb-xl")}>
          <div className={cx("icon-container", "line-graph-icon")}></div>
          <Text styleguide="heading5">
            Success based pricing to align our incentives.
          </Text>
        </div>
        <div className={cx("profitability-feature-container-background")}></div>
      </div>
    </div>
  );
};

export default Profitability;
