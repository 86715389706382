import React from 'react';

const Loader = ({ isLoading }) => {
    if (isLoading) {
        // inline styles because of ssr
        return (
            <main>
                <section style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    <img src="https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:f-webp,fo-auto/shopdeck_home/Pulse-1.6s-151px-transparent.gif" />
                </section>
            </main>

        )
    }
}

export default Loader;