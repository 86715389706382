import React from "react";
import DisplayCard from "../DisplayCard";
import Text from "../Typography";

import classNames from "classnames/bind";
import * as style from "./BannerSuccess.module.scss";

const cx = classNames.bind(style);

const BannerSuccess = ({
  name,
  amountPaid,
  sellerId,
  phoneNumber,
  brandName,
  gstNumber,
  transactionId,
}) => {
  return (
    <div
      className={cx(
        "widget",
        "widget-hero-banner-success",
        "without-background",
        "text-center"
      )}
    >
      <img
        className={cx("mb-md", "mb-lg-desktop")}
        alt="ShopDeck-logo"
        src="https://cdn.zeplin.io/62ec03b0fbeada751f8a5998/assets/46577a52-d10e-4427-8573-95de4cf42508.svg"
      />
      <Text
        styleguide="heading1"
        styleguideSm="heading2-sm"
        align="center"
        color="white"
      >
        Welcome to ShopDeck!
      </Text>
      <Text
        styleguide="heading1"
        styleguideSm="heading2-sm"
        mb="xl"
        align="center"
        color="white"
      >
        Your Registration has been done
      </Text>
      <Text
        styleguide="body2"
        styleguideSm="body3-sm"
        align="center"
        color="white"
      >
        <span className={cx("tick")}>
          <img
            alt="ShopDeck-logo"
            src="https://cdn.zeplin.io/62ec03b0fbeada751f8a5998/assets/f4997518-a21c-4324-b702-31602af653be.svg"
          />
        </span>
        Our business representative will contact you soon to collect all other
        details and activate your ShopDeck Dashboard
      </Text>
      <Text
        styleguide="body2"
        styleguideSm="body3-sm"
        mb="xxl"
        align="center"
        color="white"
      >
        <span className={cx("tick")}>
          <img
            alt="ShopDeck-logo"
            src="https://cdn.zeplin.io/62ec03b0fbeada751f8a5998/assets/f4997518-a21c-4324-b702-31602af653be.svg"
          />
        </span>
        We have sent you a confirmation email for your successful transaction
      </Text>
      <div className={cx("widget-dispaly-card")}>
        <DisplayCard
          name={name}
          amountPaid={amountPaid}
          sellerId={sellerId}
          phoneNumber={phoneNumber}
          brandName={brandName}
          gstNumber={gstNumber}
          transactionId={transactionId}
        />
      </div>
    </div>
  );
};

export default BannerSuccess;
