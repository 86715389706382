import classNames from "classnames/bind";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import HeroBanner from "../../molecules/Banner";
import BookDemo from "../../atoms/BookDemo";
import EverythingYouNeed from "../../molecules/EverythingYouNeed";
import Features from "../../molecules/Features";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import Pricing from "../../molecules/Pricing";
import Profitability from "../../molecules/Profitability";
import Testimonials from "../../molecules/Testimonials";
import TrustedBy from "../../molecules/TrustedBy";
import * as style from "./Home.module.scss";
import Loader from "../Loader";
import {useNavigate} from "react-router-dom"
import { fireDemoFormEvent } from "../../events/bookDemoForm";

const cx = classNames.bind(style);

const Home = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

  const handleBooking = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
		fireDemoFormEvent();
    navigate("/book-demo-form");
  };
  const testimonialsRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);


  useLayoutEffect(() => setIsLoading(false), []);
  useEffect(() => {
    // Scroll to the desired section based on the hash value
    if (typeof window !== undefined) {
      const hash = window.location.hash;
      switch (hash) {
        case "#testimonials":
          if (testimonialsRef.current) {
            testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
          }
          break;
        case "#features":
          if (featuresRef.current) {
            featuresRef.current.scrollIntoView({ behavior: "smooth" });
          }
          break;
        case "#pricing":
          if (pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: "smooth" });
          }
          break;
      }
    }
  });

  return (
    <>
      {isLoading && (
        <Loader isLoading={isLoading} />
      )}
      {!isLoading && (
        <main>
          <Navbar handleBooking={handleBooking} />

          <section className={cx("widget-wrapper-home")}>
            <HeroBanner handleBooking={handleBooking} />
          </section>
          <section className={cx("widget-wrapper-home")}>
            <TrustedBy />
          </section>

          <section className={cx("widget-wrapper-home")}>
            <EverythingYouNeed handleBooking={handleBooking} />
          </section>

          <section
            id="testimonials"
            className={cx("widget-wrapper-home")}
            ref={testimonialsRef}
          >
            <Testimonials />
          </section>

          <section id="profitability" className={cx("widget-wrapper-home")}>
            <Profitability handleBooking={handleBooking} />
          </section>

          <section
            id="pricing"
            className={cx("widget-wrapper-home")}
            ref={pricingRef}
          >
            <Pricing handleBooking={handleBooking} />
          </section>

          <section
            id="features"
            className={cx("widget-wrapper-home")}
            ref={featuresRef}
          >
            <Features handleBooking={handleBooking} />
          </section>

          <Footer />

          <BookDemo handleBooking={handleBooking} />
        </main>
      )}
    </>
  );
};

export default Home;
