export const getInitials = (fullName: string | null): string => {
  const nameParts = fullName && fullName.trim().split(" ");
  let firstNameInitial = "";
  let lastNameInitial = "";

  if (nameParts && nameParts.length > 0) {
    firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    if (nameParts.length > 1) {
      lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    }
  }
  return firstNameInitial + lastNameInitial;
};
export const formatName = (fullName: string | null): string => {
  const nameParts = fullName && fullName.trim().split(" ");
  const formattedName = [];

  if (nameParts && nameParts.length > 0) {
    formattedName.push(nameParts[0][0].toUpperCase() + nameParts[0].slice(1));

    if (nameParts.length > 1) {
      formattedName.push(
        nameParts[nameParts.length - 1][0].toUpperCase() +
          nameParts[nameParts.length - 1].slice(1)
      );
    }
  }

  return formattedName.join(" ");
};
