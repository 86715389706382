import React from "react";
import DisplayCard from "../../atoms/DisplayCard";
import Footer from "../../molecules/Footer";
import { getInitials } from "../../atoms/utlis";
import useQuery from "../../hooks/useQuery";

import classNames from "classnames/bind";
import * as style from "./Success.module.scss";
import Reachout from "../../molecules/Reachout";
import BannerSuccess from "../../atoms/BannerSuccess";
import Navbar from "../../molecules/Navbar";

const cx = classNames.bind(style);

const Success = (): JSX.Element => {
  const query = useQuery();

  const name = query.get("name");
  const sellerId = query.get("sellerId");
  const phoneNumber = query.get("phoneNumber");
  const brandName = query.get("brandName");
  const gstNumber = query.get("gstNumber") || "-";
  const transactionId = query.get("transactionId");
  const amountPaid = query.get("amountPaid");
  const initials = getInitials(name);

  return (
    <main>

      <Navbar isRegistrationPages={true} isSuccessScreen={true} initials={initials}/>

      <section className={cx("widget-wrapper")}>
        <BannerSuccess
          name={name}
          amountPaid={amountPaid}
          sellerId={sellerId}
          phoneNumber={phoneNumber}
          brandName={brandName}
          gstNumber={gstNumber}
          transactionId={transactionId}
        />
      </section>

      <section className={cx("widget-wrapper")}>
        <Reachout />
      </section>

      <Footer />
    </main>
  );
};
export default Success;
