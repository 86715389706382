import classNames from "classnames/bind";
import React from "react";
import Button from "rsuite/Button";
import * as style from "./Button.module.scss";
import { ButtonComponentProps } from "./types";
const cx = classNames.bind(style);

const ButtonComponent = (props: ButtonComponentProps): JSX.Element => {
  const { size, appearance, className, ...rest } = props;

  let btnStyle, color;

  switch (appearance) {
    case 'secondary': {
      btnStyle = 'primary';
      color = null;
      break;
    }
    case 'danger': {
      btnStyle = 'primary';
      color = 'red';
      break;
    }
    case 'tertiary': {
      btnStyle = 'primary';
      color = null;
      break;
    }
    case 'ghost': {
      btnStyle = 'ghost';
      color = 'red';
      break;
    }
    default: {
      btnStyle = appearance;
    }
  }

  return (
    <Button
      size="lg"
      appearance={btnStyle}
      className={cx(
        `button-${appearance}`,
        `button-loading-${props.loading}`,
        `${rest.href ? `button-has-link` : ``}`,
        className
      )}
      {...rest}
      color={color}
    />
  );
};

export default ButtonComponent;
