import React, { useLayoutEffect } from "react";
import classNames from "classnames/bind";
import * as style from "./Testimonials.module.scss";
import OwlCarousel from "../../atoms/OwlCarousel";
import Text from '../../atoms/Typography';

const cx = classNames.bind(style);

const Testimonials = () => {

    return (

        <div className={cx("widget")}>
            <Text styleguide="heading1" styleguideSm="heading3-sm" align="center" mb="md">
                See What Brands Are Saying About <Text styleguide="heading1" styleguideSm="heading3-sm" color="primary" as="span"> ShopDeck</Text>
            </Text>
            <Text styleguide="body1" styleguideSm="body2-sm" color="gray" align="center" mb="xxl">
                These Brands manage every aspect of their Website business on
                ShopDeck.
            </Text>
            <OwlCarousel owlSelector="testimonial-carousel">
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "urban-cultry-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        Urban Cultry
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Jenish Jadafiya
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "After years of selling on Amazon, I wanted to expand my
                        business beyond the platform. Thanks to ShopDeck, I now have
                        my own website that's generating over 200 orders daily.
                        Thank you, ShopDeck!"
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "joggers-park-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        Joggers Park
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Deepit Wadhwa
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "As a Flipkart seller, I was struggling to expand my
                        business. ShopDeck not only helped me create a fantastic
                        website from scratch, but also provided me with affordable
                        shipping and 10+ ROI on Facebook marketing. Thanks to
                        ShopDeck, my business is now a success!"
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "street-mode-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        TheStreetmode
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Piyush Jain
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "I was having trouble with a high rate of return-to-origin
                        (RTO) on my website. However, since I started using the
                        ShopDeck stack, my RTOs have decreased from 50% to less than
                        20%. Thank you, ShopDeck!"
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "vihaa-jewellery-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        Vihaa Jewellery
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Jayesh Ghadiya
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "I tried creating a website on Shopify but couldn't get any
                        orders. With ShopDeck's help, I've not only achieved great
                        ROI and reduced RTOs, but also scaled my business to over
                        500 daily orders. It's more than I ever thought possible on
                        Amazon or Flipkart!"
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "om-gayatri-corp-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        Om Gayatri Corp
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Ramesh Sharma
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "Thanks to ShopDeck, I've been able to acquire over 10,000
                        customers and scale my business successfully. ShopDeck has
                        been an invaluable partner in my journey. I can't thank them
                        enough!"
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "serene-silk-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        Serene Silk
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Chirag Vaidya
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "If you thought you couldn't achieve 200+ daily orders
                        through your website, think again. ShopDeck has helped me
                        achieve this goal within just 3 months of working together.
                        Their experienced marketing team found the right customers
                        for my products on Facebook, generating 7x+ ROI."
                    </Text>
                </div>
                <div className={cx("testimonial-carousel-item")}>
                    <div className={cx("testimonial-carousel-item-company-logo", "brand-mgsv-company", "mb-xl")}></div>
                    <Text styleguide="heading4" mt="sm">
                        The Brand MGSV
                    </Text>
                    <Text styleguide="body2" mb="xl">
                        Monik Sangani
                    </Text>
                    <Text styleguide="body2" color="gray">
                        "I previously tried using Shopify to create my website, and
                        even learned digital marketing to improve my ROI. However, I
                        still struggled with high logistics costs and RTOs. With
                        ShopDeck's stack, I can now ship products at minimal costs
                        and reduce my RTOs. These were the missing pieces that made
                        my online store profitable."
                    </Text>
                </div>
            </OwlCarousel>
        </div >
    )
}
export default Testimonials;
