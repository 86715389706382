// extracted by mini-css-extract-plugin
var _1 = "Divider-module__default___UulKs";
var _2 = "Divider-module__gray-colored___LUgsM";
var _3 = "Divider-module__gray300-colored___Ov0AT";
var _4 = "Divider-module__green-colored___KMNJI";
var _5 = "Divider-module__mb-lg___VfJqT";
var _6 = "Divider-module__mb-md___R5eQM";
var _7 = "Divider-module__mb-sm___zbmSn";
var _8 = "Divider-module__mb-xl___h9uo9";
var _9 = "Divider-module__mb-xs___Axei6";
var _a = "Divider-module__mb-xxl___sKW2G";
var _b = "Divider-module__ml-lg___tFIyJ";
var _c = "Divider-module__ml-md___vrc3D";
var _d = "Divider-module__ml-sm___IxFf2";
var _e = "Divider-module__ml-xl___X6kYr";
var _f = "Divider-module__ml-xs___l3N6R";
var _10 = "Divider-module__ml-xxl___fTeUC";
var _11 = "Divider-module__mr-lg___RrtqA";
var _12 = "Divider-module__mr-md___aFtHv";
var _13 = "Divider-module__mr-sm___hV4we";
var _14 = "Divider-module__mr-xl___xXnrx";
var _15 = "Divider-module__mr-xs___iDY1H";
var _16 = "Divider-module__mr-xxl___yPmeg";
var _17 = "Divider-module__mt-lg___pqQtT";
var _18 = "Divider-module__mt-md___QLlGu";
var _19 = "Divider-module__mt-sm___pQkNl";
var _1a = "Divider-module__mt-xl___OIWql";
var _1b = "Divider-module__mt-xs___oPWtr";
var _1c = "Divider-module__mt-xxl___kntIT";
var _1d = "Divider-module__primary-colored____XSK8";
var _1e = "Divider-module__red-colored___eOAO4";
var _1f = "Divider-module__secondary-colored___TM28i";
var _20 = "Divider-module__tertiary-colored___l2Xwe";
var _21 = "Divider-module__white-colored___TnSbQ";
export { _1 as "_default", _2 as "gray-colored", _3 as "gray300-colored", _4 as "green-colored", _5 as "mb-lg", _6 as "mb-md", _7 as "mb-sm", _8 as "mb-xl", _9 as "mb-xs", _a as "mb-xxl", _b as "ml-lg", _c as "ml-md", _d as "ml-sm", _e as "ml-xl", _f as "ml-xs", _10 as "ml-xxl", _11 as "mr-lg", _12 as "mr-md", _13 as "mr-sm", _14 as "mr-xl", _15 as "mr-xs", _16 as "mr-xxl", _17 as "mt-lg", _18 as "mt-md", _19 as "mt-sm", _1a as "mt-xl", _1b as "mt-xs", _1c as "mt-xxl", _1d as "primary-colored", _1e as "red-colored", _1f as "secondary-colored", _20 as "tertiary-colored", _21 as "white-colored" }
