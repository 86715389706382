// extracted by mini-css-extract-plugin
var _1 = "Features-module__blue-tick___N9CvL";
var _2 = "Features-module__ctm-content-container___N063z";
var _3 = "Features-module__ctm-media-container___mlQek";
var _4 = "Features-module__ctm-reverse___BHLXG";
var _5 = "Features-module__green-tick___hp0Bj";
var _6 = "Features-module__marketing-icon___h4yf_";
var _7 = "Features-module__shipping-icon___eUFhO";
var _8 = "Features-module__storefront-icon___mmu2T";
var _9 = "Features-module__tick-container___bJbRL";
var _a = "Features-module__whatsapp-icon___fxoY6";
var _b = "Features-module__widget-ctm___xvQyA";
var _c = "Features-module__widget-modules-carousel-item___l0SKU";
var _d = "Features-module__widget-modules-feature-list-item___JE3_T";
export { _1 as "blue-tick", _2 as "ctm-content-container", _3 as "ctm-media-container", _4 as "ctm-reverse", _5 as "green-tick", _6 as "marketing-icon", _7 as "shipping-icon", _8 as "storefront-icon", _9 as "tick-container", _a as "whatsapp-icon", _b as "widget-ctm", _c as "widget-modules-carousel-item", _d as "widget-modules-feature-list-item" }
