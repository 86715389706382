import React from "react";
import { BookDemoProps } from "./types";
import * as styles from "./BookDemo.module.scss"
import classNames from "classnames/bind";
import Button from "../Button";

const cx = classNames.bind(styles);

const BookDemo = (props: BookDemoProps): JSX.Element => {
  const { handleBooking } = props;
  return (
    <div className={cx("demo-button-sticky", "hidden-desktop")}>
      <Button appearance="tertiary" size="lg" className={cx("full-width-button")} onClick={handleBooking}>
        Book a free Demo
      </Button>
    </div>
  );
};

export default BookDemo;
