import React from "react";
import Text from "../../atoms/Typography";
import * as styles from "./Pricing.module.scss";
import classNames from "classnames/bind";
import Button from "../../atoms/Button";

const cx = classNames.bind(styles);

const Pricing = ({ handleBooking }) => {
  return (
    <div className={cx("widget", "widget-ctm", "without-background")}>
      <div className={cx("ctm-media-container")}>
        <img
          alt="ShopDeck-Features"
          src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/d375f9ac-04db-46fd-8739-716c9e12b862.svg"
        />
      </div>
      <div className={cx("ctm-content-container")}>
        <Text
          styleguide="heading1"
          styleguideSm="heading2-sm"
          mb="md"
          color="tertiary"
        >
          <Text styleguide="heading1"
            styleguideSm="heading2-sm" as="span"
            color="red">
            Pricing&nbsp;
          </Text>
          no upfront cost, only
          success based fees
        </Text>
        <Text styleguide="body1" styleguideSm="body2-sm" mb="xl" color="gray">
          Success based pricing ensures we make money only when you make money.
          Starts at 3%+GST of total delivered sales.
        </Text>
        <div>
          <Button size="md" appearance="tertiary" onClick={handleBooking}>
            Let's get started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
