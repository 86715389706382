import React from "react";
import { CardProps } from "./types";
import * as style from "./Card.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

const Card = (props: React.PropsWithChildren<CardProps>): JSX.Element => {
  const {
    as: As = "section",
    children,
    noPadding,
    noShadow,
    className,
    mt,
    mb,
    ...rest
  } = props;

  return (
    <As
      className={cx(
        className,
        `card`,
        `${noShadow ? `no-shadow` : ""}`,
        `${noPadding ? `no-padding` : ""}`,
        `${mt ? `mt-${mt}` : ""}`,
        `${mb ? `mb-${mb}` : ""}`
      )}
      {...rest}
    >
      {children}
    </As>
  );
};

export default Card;
