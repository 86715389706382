import React from "react";
import * as styles from "./Features.module.scss";
import Text from "../../atoms/Typography";
import classNames from "classnames/bind";
import OwlCarousel from "../../atoms/OwlCarousel";
import Button from "../../atoms/Button";

const cx = classNames.bind(styles);

const Features = ({ handleBooking }) => {
  return (
    <div className={cx("widget")}>
      <div className={cx("widget-ctm", "ctm-reverse", "mb-xxl")}>
        <div className={cx("ctm-media-container")}>
          <img
            alt="ShopDeck-Features"
            src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/c9d4479f-65a9-4fe3-9d24-ae2c3066e6f2-optimized.png"
          />
        </div>
        <div className={cx("ctm-content-container")}>
          <Text
            styleguide="heading1"
            styleguideSm="heading3-sm"
            mb="md"
            color="tertiary"
          >
            <Text styleguide="heading1" styleguideSm="heading3-sm" as="span" color="primary">Features&nbsp;</Text>
            to grow your
            business Profitably
          </Text>
          <div>
            <Button size="md" appearance="tertiary" onClick={handleBooking}>
              Let's get started
            </Button>
          </div>
        </div>
      </div>
      <OwlCarousel owlSelector="widget-modules-carousel">
        <div className={cx("widget-modules-carousel-item")}>
          <div
            className={cx("icon-container", "marketing-icon", "mb-xl")}
          ></div>
          <Text
            styleguide="heading4"
            styleguideSm="heading4-sm"
            mb="sm"
            color="tertiary"
          >
            Marketing
          </Text>
          <div className={cx("widget-modules-feature-list")}>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Integration with Facebook and Google for ads management.
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Optimization for lower RTO.
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Low/No Stock alerts for smooth performance.
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Postpaid Marketing: Pay after order delivery.
              </Text>
            </div>
          </div>
        </div>
        <div className={cx("widget-modules-carousel-item")}>
          <div
            className={cx("icon-container", "storefront-icon", "mb-xl")}
          ></div>
          <Text
            styleguide="heading4"
            styleguideSm="heading4-sm"
            mb="sm"
            color="tertiary"
          >
            Storefront
          </Text>
          <div className={cx("widget-modules-feature-list")}>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Incredibly fast speeds
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                4 themes with full customization on offer
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                SEO optimized
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Free android app (beta)
              </Text>
            </div>
          </div>
        </div>
        <div className={cx("widget-modules-carousel-item")}>
          <div className={cx("icon-container", "whatsapp-icon", "mb-xl")}></div>
          <Text
            styleguide="heading4"
            styleguideSm="heading4-sm"
            mb="sm"
            color="tertiary"
          >
            WhatsApp
          </Text>
          <div className={cx("widget-modules-feature-list")}>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "green-tick")}></span>
              <Text styleguide="body2" color="gray">
                Cart Drop-off retargeting.
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "green-tick")}></span>
              <Text styleguide="body2" color="gray">
                Order updates to customers
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "green-tick")}></span>
              <Text styleguide="body2" color="gray">
                Automated NDR feedback
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "green-tick")}></span>
              <Text styleguide="body2" color="gray">
                Driving repeats via Whatsapp
              </Text>
            </div>
          </div>
        </div>
        <div className={cx("widget-modules-carousel-item")}>
          <div className={cx("icon-container", "shipping-icon", "mb-xl")}></div>
          <Text
            styleguide="heading4"
            styleguideSm="heading4-sm"
            mb="sm"
            color="tertiary"
          >
            Shipping
          </Text>
          <div className={cx("widget-modules-feature-list")}>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Intelligent features to minimise RTO
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                20+ Shipping Partners integrated
              </Text>
            </div>
            <div className={cx("widget-modules-feature-list-item")}>
              <span className={cx("tick-container", "blue-tick")}></span>
              <Text styleguide="body2" color="gray">
                Logistics will be charged after delivery.
              </Text>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default Features;
