// extracted by mini-css-extract-plugin
var _1 = "Text-module__body1___M2RUT";
var _2 = "Text-module__body1-sm___vWPA1";
var _3 = "Text-module__body2___ofQDW";
var _4 = "Text-module__body2-sm___goWe2";
var _5 = "Text-module__body3___UGDTZ";
var _6 = "Text-module__body3-sm___mMeuo";
var _7 = "Text-module__body4___R_aXS";
var _8 = "Text-module__body4-sm___u5ck0";
var _9 = "Text-module__bold___gV61Y";
var _a = "Text-module__bolder___k1uei";
var _b = "Text-module__break-word___ilOmb";
var _c = "Text-module__cursor-pointer___bS5Ue";
var _d = "Text-module__ellipsis___p8oCz";
var _e = "Text-module__gray-colored___jUrbQ";
var _f = "Text-module__gray300-colored___baGq7";
var _10 = "Text-module__green-colored___ymK_H";
var _11 = "Text-module__heading1-sm___AIQan";
var _12 = "Text-module__heading2-sm___t4A2E";
var _13 = "Text-module__heading3-sm___AeFk2";
var _14 = "Text-module__heading4-sm___rT4Wz";
var _15 = "Text-module__heading5-sm___O5R4D";
var _16 = "Text-module__heading6-sm___pj6Lc";
var _17 = "Text-module__lighter___wQ3Ry";
var _18 = "Text-module__mb-lg___oTd9T";
var _19 = "Text-module__mb-md___tlt4u";
var _1a = "Text-module__mb-sm___gjWbf";
var _1b = "Text-module__mb-xl___JlD8a";
var _1c = "Text-module__mb-xs___L8KkA";
var _1d = "Text-module__mb-xxl___IVBsV";
var _1e = "Text-module__ml-lg___bBg_c";
var _1f = "Text-module__ml-md___olv70";
var _20 = "Text-module__ml-sm___R_DAM";
var _21 = "Text-module__ml-xl___CogbD";
var _22 = "Text-module__ml-xs___YBQhZ";
var _23 = "Text-module__ml-xxl___dzGb3";
var _24 = "Text-module__mr-lg___hmHzC";
var _25 = "Text-module__mr-md___hLcvx";
var _26 = "Text-module__mr-sm___glodm";
var _27 = "Text-module__mr-xl___aRQ0t";
var _28 = "Text-module__mr-xs___AAqp9";
var _29 = "Text-module__mr-xxl___DYFgA";
var _2a = "Text-module__mt-lg___E4YrF";
var _2b = "Text-module__mt-md____UIhL";
var _2c = "Text-module__mt-sm___XNkid";
var _2d = "Text-module__mt-xl___ABo_3";
var _2e = "Text-module__mt-xs___clAwn";
var _2f = "Text-module__mt-xxl___BR6mb";
var _30 = "Text-module__normal___L3fGv";
var _31 = "Text-module__primary-colored___NETTM";
var _32 = "Text-module__red-colored___jFw4X";
var _33 = "Text-module__secondary-colored___JclFW";
var _34 = "Text-module__strike-through___apDHv";
var _35 = "Text-module__tertiary-colored___a8n4f";
var _36 = "Text-module__text-align-center___SzJ9b";
var _37 = "Text-module__text-align-left___rvDrt";
var _38 = "Text-module__text-align-right___q5U_8";
var _39 = "Text-module__text-transform-capitalize___tHmnb";
var _3a = "Text-module__text-transform-lowercase___RdFK5";
var _3b = "Text-module__text-transform-uppercase___KBGXP";
var _3c = "Text-module__underline___vKGdY";
var _3d = "Text-module__white-colored___kQ3zD";
export { _1 as "body1", _2 as "body1-sm", _3 as "body2", _4 as "body2-sm", _5 as "body3", _6 as "body3-sm", _7 as "body4", _8 as "body4-sm", _9 as "bold", _a as "bolder", _b as "break-word", _c as "cursor-pointer", _d as "ellipsis", _e as "gray-colored", _f as "gray300-colored", _10 as "green-colored", _11 as "heading1-sm", _12 as "heading2-sm", _13 as "heading3-sm", _14 as "heading4-sm", _15 as "heading5-sm", _16 as "heading6-sm", _17 as "lighter", _18 as "mb-lg", _19 as "mb-md", _1a as "mb-sm", _1b as "mb-xl", _1c as "mb-xs", _1d as "mb-xxl", _1e as "ml-lg", _1f as "ml-md", _20 as "ml-sm", _21 as "ml-xl", _22 as "ml-xs", _23 as "ml-xxl", _24 as "mr-lg", _25 as "mr-md", _26 as "mr-sm", _27 as "mr-xl", _28 as "mr-xs", _29 as "mr-xxl", _2a as "mt-lg", _2b as "mt-md", _2c as "mt-sm", _2d as "mt-xl", _2e as "mt-xs", _2f as "mt-xxl", _30 as "normal", _31 as "primary-colored", _32 as "red-colored", _33 as "secondary-colored", _34 as "strike-through", _35 as "tertiary-colored", _36 as "text-align-center", _37 as "text-align-left", _38 as "text-align-right", _39 as "text-transform-capitalize", _3a as "text-transform-lowercase", _3b as "text-transform-uppercase", _3c as "underline", _3d as "white-colored" }
