// extracted by mini-css-extract-plugin
var _1 = "Footer-module__about-shopdeck___EpGn4";
var _2 = "Footer-module__aux-links___tYkh5";
var _3 = "Footer-module__contact-details___UqVRH";
var _4 = "Footer-module__contact-details-item___DI825";
var _5 = "Footer-module__copyright-subfooter___9pcjo";
var _6 = "Footer-module__email___tUek1";
var _7 = "Footer-module__footer___PvQoX";
var _8 = "Footer-module__footer-contact-item___DeIA6";
var _9 = "Footer-module__hero-links___MUJ0q";
var _a = "Footer-module__location___G9h3y";
var _b = "Footer-module__main-footer___JmcCj";
var _c = "Footer-module__phone-number___K7_Fe";
var _d = "Footer-module__widget-wrapper___Wl6S2";
export { _1 as "about-shopdeck", _2 as "aux-links", _3 as "contact-details", _4 as "contact-details-item", _5 as "copyright-subfooter", _6 as "email", _7 as "footer", _8 as "footer-contact-item", _9 as "hero-links", _a as "location", _b as "main-footer", _c as "phone-number", _d as "widget-wrapper" }
