import React from "react";
import Text from "../../atoms/Typography";
import * as styles from "./TermsCondition.module.scss";
import classNames from "classnames/bind";
import Link from "../../atoms/Link";

const cx = classNames.bind(styles);

const Terms = (): JSX.Element => {
  return (
    <div className={cx("widget", "widget-privacy-policy")}>
      <Text
        styleguide="heading3"
        styleguideSm="heading3-sm"
        color="tertiary"
        fontWeight="bold"
        align="center"
        underline
      >
        TERMS AND CONDITIONS OF SHOPDECK SERVICES
      </Text>

      <ul className={cx("no-list-style-type")}>
        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            1. INTRODUCTION
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.1
            </Text>
            Blitzscale Technology Solutions Private Limited ({" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Company"
            </Text>
            ) is a private limited company engaged in the business of
            developing, operating and managing a software-as-a-service platform
            to facilitate and enable D2C businesses to create their online
            e-commerce store by way of website and / or mobile application, and
            provide them with all the essential tools they need to manage their
            e-commerce store ({" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Business"
            </Text>
            ) The Company also provides certain ancillary services which are
            covered in more detail under Clause 3 of these Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.2
            </Text>{" "}
            As used in these Terms,{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “we”
            </Text>
            ,{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “us”
            </Text>
            ,
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “our”
            </Text>{" "}
            and{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “company”
            </Text>{" "}
            means Blitzscale Technology Solutions Private Limited, and “you”
            means the service recipient of Company's services (which shall
            include individual, or any business registering with the Company and
            shall include their affiliates).
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.3
            </Text>{" "}
            You have approached the Company to avail the Services (as defined
            hereinafter) and the Company, subject to your acceptance of these
            Terms, has agreed to provide you some or all of the Services (as may
            be requested by you from time to time).
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.4
            </Text>{" "}
            You and the Company are hereinafter individually referred to as{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Party”
            </Text>{" "}
            and together as{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Parties”
            </Text>{" "}
            .
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.5
            </Text>{" "}
            You and the Company are hereinafter individually referred to as The
            terms and conditions outlined herein (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Terms”
            </Text>
            ) shall govern the contractual relationship between the Parties and
            shall also govern the use of the Services made available on or
            through
            <Link relative color="tertiary" underline target="_blank">
              {" https://shopdeck.com/ "}
            </Link>
            and related domains including but not limited to
            <Link relative color="tertiary" underline target="_blank">
              {" https://pro.shopdeck.com/ "}
            </Link>
            and/ or the Company's mobile app (collectively the{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Platform”
            </Text>
            ).
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.6
            </Text>{" "}
            These Terms shall be read with the Company's privacy policy uploaded
            to the Company's website (
            <Link relative color="tertiary" underline target="_blank">
              www.shopdeck.com
            </Link>
            ) as amended from time to time (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Privacy Policy"
            </Text>
            ), and any guidelines, additional, or supplemental terms, policies,
            and disclaimers made available or issued by the Company to you from
            time to time (collectively
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Supplemental Terms"
            </Text>
            ). The Privacy Policy and the Supplemental Terms form an integral
            part of the Terms. In the event of a conflict between the Terms and
            the Supplemental Terms, the Supplemental Terms shall prevail.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.7
            </Text>{" "}
            You hereby represent and warrant that you have the legal capacity
            and full authority to agree and bind yourself to these Terms. If you
            represent any other person, you confirm and represent that you have
            the necessary power and authority to bind such person to these
            Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              1.8
            </Text>{" "}
            For ease of reference, Company may publish or provide you, with a
            copy of the Terms in languages other than English. However, if there
            is any discrepancy in the language or meaning between the Terms
            drafted in English and a copy of the Terms in a language other than
            English, the Terms drafted in English shall prevail.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            2. COMMENCEMENT AND DURATION
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              2.1
            </Text>{" "}
            These Terms shall be effective and binding on you upon clicking{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "I Agree"
            </Text>
            .
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              2.2
            </Text>{" "}
            These Terms shall commence on clicking{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "I Agree"
            </Text>{" "}
            and shall remain in full force and effect until either the Company,
            or you terminate the Terms in accordance with the termination
            provisions as enumerated under these Terms. .
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              2.3
            </Text>
            You acknowledge that the Company will use the email address or
            contact number you provide while registering with the Company or as
            updated by you from time to time as the primary method for
            communication with you (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Primary Contact"
            </Text>
            ). You must monitor the Primary Contact you provide to the Company
            and your Primary Contact must be capable of both sending and
            receiving messages. Your email communications with the Company can
            only be authenticated if they come from your Primary Contact.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              2.4
            </Text>
            You are responsible for maintaining the security of your account (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "Account"
            </Text>
            ) password. The Company cannot and will not be liable for any loss
            or damage resulting from your failure to secure your Account and
            password. We may require additional security measures at any time
            and reserve the right to adjust these requirements at our
            discretion.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            3. SERVICES
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            3.1 Scope of Services.
          </Text>
          <ul className={cx("no-list-style-type")}>
            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  3.1.1
                </Text>
                You may avail one or all of the services as listed below from
                the Company:
              </Text>
              <ol type="a">
                <li>
                  <Text as="div" styleguide="body1" color="gray" mt="xl">
                    To set up a fully functional website that can be hosted on
                    your own domain, or the Company will provide you with a
                    domain if you require so for which you shall be accountable
                    and Company shall not have any liability arising in
                    connection with such domain.
                  </Text>
                </li>

                <li>
                  <Text as="div" styleguide="body1" color="gray" mt="xl">
                    To set up mobile application tool to create your own mobile
                    application.
                  </Text>
                </li>

                <li>
                  <Text as="div" styleguide="body1" color="gray" mt="xl">
                    Aggregation of logistics as required by you by facilitating
                    the shipment of products by you to its end user by availing
                    services of various logistic companies (
                    <Text
                      as="span"
                      styleguide="body1"
                      fontWeight="bold"
                      color="tertiary"
                    >
                      “Shipping Aggregation”
                    </Text>
                    ).
                  </Text>
                </li>

                <li>
                  <Text as="div" styleguide="body1" color="gray" mt="xl">
                    Provide marketing, promotional and advertising strategy to
                    you for your e-commerce business (
                    <Text
                      as="span"
                      styleguide="body1"
                      fontWeight="bold"
                      color="tertiary"
                    >
                      “Marketing Service”
                    </Text>
                    ).
                    <Text as="div" styleguide="body1" color="gray" mt="md">
                      The services as listed hereinabove are collectively
                      referred to as{" "}
                      <Text
                        as="span"
                        styleguide="body1"
                        fontWeight="bold"
                        color="tertiary"
                      >
                        “Services”
                      </Text>
                      .
                    </Text>
                  </Text>
                </li>
              </ol>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  3.1.2
                </Text>
                The specific services from the basket of Services as enumerated
                herein above and as required by you will be agreed between the
                Parties, in writing, from time to time.
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  3.1.3
                </Text>
                The Terms shall govern the scope of Services as agreed to be
                rendered by the Company to you.
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  3.1.4
                </Text>{" "}
                You agree not to reproduce, duplicate, copy, sell, resell, or
                exploit any part of the Services or access to the Services
                without Company’s prior written consent.
              </Text>
            </li>
          </ul>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            4. PRICING, PAYMENT TERMS AND TAXES
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.1
            </Text>
            You will pay such fee (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Fee”
            </Text>
            ) to the Company for provision of the Services as may be mutually
            agreed between the Company and you, from time to time.. You agree
            and acknowledge that the Fee covers the Services provided by the
            Company. Any third-party charges and out of pocket expenses
            including shipment charges for shipping the products through
            logistics partners and advertising charges or expenses incurred by
            the Company on your marketing/advertising activities (as approved by
            you in any form and manner from time to time) on various platforms,
            will be billed separately and are not included in the Fee. For the
            avoidance of doubt, all sums payable by you to the Company under
            these Terms will be paid free and clear of any deductions or
            withholdings whatsoever. Other than taxes charged by the Company to
            you and remitted to the appropriate tax authorities on your behalf,
            any deductions or withholdings that are required by law will be
            borne by you and paid separately to the relevant taxation authority.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.2
            </Text>
            The Company will raise an invoice for the Fee and all other expenses
            specified in Clause 4.1 above on you.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.3
            </Text>
            The Fee shall be payable by you as per the invoices raised by the
            Company and shall be paid / discharged by you within 30 (thirty)
            days from the receipt of the invoice (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Payment Period”
            </Text>
            ). In the event you fail to make the payment of the relevant invoice
            within the Payment Period, without prejudice to any other rights and
            remedies that the Company may have under these Terms or under
            applicable laws, a simple interest at an annual rate of 18%
            (eighteen percent) will accrue on the outstanding amount of the
            invoice for the period extending beyond the Payment Period. Without
            prejudice to the foregoing, in such cases of default or delayed
            payment of Fee, the Company reserves the right to suspend provision
            of the Services, for the period of default, without any liability or
            obligation towards the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.4
            </Text>
            If you chose to avail Shipping Aggregation service, you agree to pay
            additional charges on the basis of weight delta, if the product
            weight/dimensions provided are different from the actual shipment.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.5
            </Text>
            You agree that the Company shall have the right to modify the Fee
            for the Services by way of giving a notice, without the need to
            execute any addendum or amendment agreement to these Terms for each
            change of Fee. Such modification shall be in effect from the date
            communicated by the Company in the notice, and the previously agreed
            Fee shall stand revised, with all the other provisions of these
            Terms subsisting as is. It is further clarified that any payments
            due to the Company from you shall be communicated and disbursed in
            accordance with the notice sent by the Company to you, from time to
            time.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.6
            </Text>
            You agree to remit such amount as security deposit (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Security Deposit”
            </Text>
            ) as agreed between the Company and you, as security for the payment
            of the invoices raised by the Company, for its Services. The Company
            shall release such Security Deposit to you forthwith, provided that,
            you have availed Services of an amount equivalent to the [∙] (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Minimum Amount”
            </Text>
            ), and makes the full requisite payments of invoices of such Minimum
            Amount. Further, the refund of Security Deposit to you shall also be
            conditioned upon the fact that you will in minimum spend INR
            20,000/- (Indian Rupees Twenty Thousand only) on sales, promotion
            and advertisement. Notwithstanding anything to the contrary, the
            Company reserves the right to adjust any outstanding Fees from the
            Security Deposit after the Payment Period and to terminate its
            engagement with you.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              4.7
            </Text>
            The Company shall have the right to hold and adjust from any money
            collected by the Company on behalf of you to recover Fee and other
            reimbursements specified in Clause 4.1 above which is owed and
            payable to the Company by you. Further, the Company shall have the
            right to recover third party expenses incurred by the Company, from
            the money collected by the Company on your behalf before remitting
            the balance to you.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            5. INTELLECTUAL PROPERTY
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              5.1
            </Text>
            All rights, titles, and interest in, and to the Services, including
            all intellectual property rights arising out of the use of Services,
            are owned by or otherwise licensed to the Company. Subject to
            compliance with these Terms, Company grants you a non-exclusive,
            non-transferable, non-sub licensable, revocable, and limited license
            to use the Services in accordance with these Terms and Company’s
            written instructions issued from time to time. Any rights not
            expressly granted herein are reserved by the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              5.2
            </Text>
            Except as expressly stated in these Terms, nothing in these Terms
            should be construed as conferring any right in, or license to, the
            Company’s or any third party's intellectual property rights in your
            favour.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              5.3
            </Text>
            You agree that you may not use any trademarks, logos, or service
            marks of the Company, whether registered or unregistered, including
            but not limited to the word mark ShopDeck (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Company Trademarks”
            </Text>
            ) unless you are authorized to do so by the Company in writing. You
            agree not to use or adopt any marks that may be considered confusing
            with the Company Trademarks.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            6. CONFIDENTIALITY
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.1
            </Text>
            You shall protect and preserve the confidential information shared
            by the Company using same degree of care as will be exercised for
            the confidential information owned by you and shall not use the
            confidential information for any purpose other than the limited
            purpose of Services under these Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.2
            </Text>
            You shall not disclose confidential or proprietary information by
            any means that is not authorized by the Company to any third
            parties, both during the term of the Terms and for at least one (1)
            year following its termination.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.3
            </Text>
            You shall avoid making copies of or duplicating the Company’s
            confidential and proprietary information unless directed to do so by
            the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.4
            </Text>
            You shall use the confidential information provided by the Company
            solely for the purpose that is explicitly authorized by the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.5
            </Text>
            You shall inform Company immediately if you become aware of any
            unauthorized disclosure or use of any confidential or proprietary
            information; and
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.6
            </Text>
            You acknowledge and agree that, as between you and the Company, the
            confidential information, together with all intellectual property
            rights embodied therein (including, but not limited to, all patent
            rights, inventions (whether patentable or not), concepts, ideas,
            algorithms, formulae, processes, methods, techniques, copyrights,
            copyrightable works, trade secrets, know-how, and trademarks) are
            the sole and exclusive property of the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              6.7
            </Text>
            Failure to adhere to any of the terms contained in this clause may
            result in the termination of these Terms between the Company and
            you, and Company may initiate legal action against you.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            7. TERMINATION AND ITS CONSEQUENCES
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              7.1
            </Text>
            The Terms can be terminated by the Company without any cause with
            written notice to you. Upon termination of the agreement, you shall
            not be entitled to any compensation. These Terms shall remain in
            effect unless terminated in accordance with the provisions
            hereunder.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              7.2
            </Text>
            You may terminate these Terms without any cause with 10 (ten) days
            prior written notice to the Company.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              7.3
            </Text>
            The Company may restrict, deactivate, or terminate your access to,
            or use of, the Services, any portion thereof, or immediately, and at
            any point at Company’s sole discretion, (i) if you violate or breach
            any of the obligations, responsibilities, or covenants under these
            Terms, (ii) when you cease to become a user of Company’s Services,
            (iii) you do not, or not likely to qualify under applicable law, or
            the standards and policies of Company, to access and use the
            Services, (iv) violate or breach the Privacy Policy and / or the
            Supplemental Terms (if any), or (v) for any legitimate business,
            legal, or regulatory reason.
          </Text>
        </li>

        <li>
          {" "}
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              7.4
            </Text>
            Upon termination of these Terms:
          </Text>
          <ul className={cx("no-list-style-type")}>
            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.1
                </Text>
                the Services will "time-out";
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.2
                </Text>
                your right to participate on the Platform, shall automatically
                terminate;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.3
                </Text>
                all rights or licenses granted to you under these Terms shall
                stand cancelled;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.4
                </Text>
                you shall return and if not asked to return, destroy within 15
                days, all Company’s data, trademarks, service marks, or content,
                in your possession or control;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.5
                </Text>
                you shall permanently erase all proprietary and confidential
                information from your electronic devices;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.6
                </Text>
                Subject to clause 7.4.4 above, you shall comply with the above
                stipulations within 7 days of termination and certify in writing
                that you have complied with the above stipulations;{" "}
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  7.4.7
                </Text>
                your final settlement will be done on or before 45 days of the
                effective date of termination of the Terms
              </Text>
            </li>
          </ul>
        </li>

        <li>
          {" "}
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              7.5
            </Text>
            The Terms shall terminate, except for those clauses that are
            expressly, or by implication, intended to survive termination or
            expiry.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            8. DISCLAIMERS AND WARRANTIES OF COMPANY
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.1
            </Text>
            The Services are provided by the Company on an “as is" basis without
            warranty of any kind, express, implied, statutory, or otherwise,
            including without limitation the implied warranties of title,
            non-infringement, merchantability, or fitness for a particular
            purpose. Without limiting the foregoing, the Company makes no
            warranty that the Services will meet your requirements or
            expectations.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.2
            </Text>
            No advice or information, whether oral or written, obtained by you
            from Company shall create any warranty that is not expressly stated
            in the Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.3
            </Text>
            The Company shall not be liable in any manner for any obligations
            that have not been explicitly stated in these Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.4
            </Text>
            It is hereby clarified that the Company provides Shipping
            Aggregation services to you through third-party logistics partners
            engaged by the Company and does not engage in actual shipping and
            delivery of products. Further, the Company does not engage in any
            warehousing and / or packaging services.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.5
            </Text>
            The relationship between you and the Company is voluntary,
            non-exclusive, on a principal-to-principal basis, and the Parties
            are free to enter into any other arrangements or agreements, with
            any third-party.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.6
            </Text>
            The Company is not engaged in the provision, grant, or disbursement
            of any products. The Company is not and will not be responsible for
            any claim or for any damage suffered that is related, directly or
            indirectly, to or arises out of the use of Services.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.7
            </Text>
            The Company reserves the right to block your access to the Platform
            if you are in breach of any material terms and conditions of the
            Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.8
            </Text>
            The Company does not guarantee that the Services, or any portion
            thereof, will function on any particular hardware or device.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.8
            </Text>
            The Company does not guarantee that the Services, or any portion
            thereof, will function on any particular hardware or device.
          </Text>
          <ul className={cx("no-list-style-type")}>
            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  8.9.1
                </Text>
                Occurrence of force majeure event as provided in clause 13 of
                these Terms;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  8.9.2
                </Text>
                Your use of or inability to use, or availability or
                unavailability of the Services including any action undertaken
                by the Company with your approval or pursuant to your
                instructions for providing the Services;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  8.9.3
                </Text>
                the occurrence or existence of any defect, interruption, or
                delays, in the operation or transmission of information to,
                from, or through the Services, communications failure, theft,
                destruction, or unauthorized access to records, programs,
                services, servers, or other infrastructure relating to the
                Services; and
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  8.9.4
                </Text>
                the failure of the Services to remain operational for any period
                of time.
              </Text>
            </li>
          </ul>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.10
            </Text>
            In no event shall Company, its officers, directors, and employees,
            or its contractors, agents, licensors, partners, or suppliers, be
            liable to you for any direct, special, indirect, incidental,
            consequential, punitive, reliance, or exemplary damages (including
            without limitation, lost business opportunities, lost revenues, or
            loss of anticipated profits or any other pecuniary or non-pecuniary
            loss or damage of any nature whatsoever), including but not limited
            to, any abuse or breach of data, even if Company or an authorized
            representative had been advised of the possibility of such damages,
            arising out of, or relating to (a) these Terms; (b) the Services;
            (c) your use or inability to use the Services; or (d) any other
            interactions with another user of the Services.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.11
            </Text>
            Nothing in these Terms will exclude or limit any warranty implied by
            law that it would be unlawful to exclude or limit. To the extent
            such a warranty implied by law applies, and to the extent the
            Company is permitted under applicable law to do so, the Company’s
            liability will be limited, at its option.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.12
            </Text>
            You alone shall be responsible for compliance with all applicable
            laws as associated with the marketing, sales, storage and
            distribution of the products and more so for compliance with the
            Legal Metrology Act, 2009 and the Packaged Commodities Rules, 2011
            thereunder.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.13
            </Text>
            The Company shall not be held liable in any manner whatsoever for
            the smooth functioning of the payment gateway / payment aggregator
            being relied upon by you.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.14
            </Text>
            The Company shall not be liable in any manner whatsoever for any
            failure whether in terms of quality or terms of response time in the
            communication services provided by the Company to you.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.15
            </Text>
            You are responsible for your e-commerce store, the goods or services
            you sell, and your relationship with your customers, not us.{" "}
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              8.16
            </Text>
            You are solely responsible for determining, collecting, withholding,
            reporting, and remitting applicable taxes, duties, fees, surcharges
            and additional charges that arise to you from or as a result of any
            sale on your e-commerce store or your use of the Services. The
            Services provided by the Company shall not be construed as
            marketplace. Any contract of sale made by a user on your e-commerce
            store through the Services is directly between you and such user.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            9. DATA PRIVACY
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              9.1
            </Text>
            For the purposes of providing Services to you, the Company may
            collect, store, use and process the personal data (including
            sensitive personal data) such as address, e-mail address, contact
            details etc. of your end customers (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “End Customer Data”
            </Text>
            ).
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              9.2
            </Text>
            You shall obtain prior consent from the end customers for
            transferring the End Customer Data for collection, storage, usage
            and processing of such data by the Company and shall comply with all
            measures as may be required or prudent to be complied with in order
            to ensure compliance with applicable laws (including any data
            privacy laws).
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            10. INDEMNIFICATION
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              10.1
            </Text>
            You shall indemnify, defend at Company’s option, and hold Company,
            its subsidiaries, affiliates, and its officers, employees,
            directors, agents, and representatives, harmless from and against
            any claim, demand, lawsuits, judicial proceeding, losses,
            liabilities, damages, and costs (including, without limitation, all
            damages, liabilities, settlements, and attorneys’ fees), due to, or
            arising out of:
          </Text>

          <ul className={cx("no-list-style-type")}>
            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  10.1.1
                </Text>
                Your access and use of the Services, or any violation of these
                Terms by you;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  10.1.2
                </Text>
                breach of the terms of logistic partners or any applicable laws
                in relation to the packaging, storing, and shipment of goods;
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  10.1.3
                </Text>
                breach of applicable laws including but not limited to Legal
                Metrology (Packaged Commodities) Amendment Rules, 2017 and
                guidelines issued by any self-regulatory organization including
                Advertising Standards Council of India; and
              </Text>
            </li>

            <li>
              <Text as="div" styleguide="body1" color="gray" mt="xl">
                <Text as="span" styleguide="body1" color="gray" mr="md">
                  10.1.4
                </Text>
                breach of applicable data privacy laws by you in relation to the
                collection, storage and usage of information of the end
                customers.
              </Text>
            </li>
          </ul>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              10.2
            </Text>
            You shall also enable the Company to initiate legal action for
            recovery of dues against third party(s) such as shipping agency,
            marketing agency etc. towards refund claim arising on account of
            lost / damaged goods or for claim of refund on return of goods.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            11. GOVERNING LAW
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              11.1
            </Text>
            These Terms shall be governed by, construed and enforced in
            accordance with, the substantive laws of the India without regard to
            its principles of conflicts of laws
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            12. DISPUTE RESOLUTION
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              12.1
            </Text>
            [If you wish to raise a dispute, claim, or conflict, which arises
            out of, or in connection with these Terms or the Services, you may
            raise a complaint, or raise a dispute, to your designated single
            point of contact (
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “Designated SPOC”
            </Text>
            ). If your complaint or grievance does not get resolved within 3
            (three) business days of communicating your complaint to the
            Designated SPOC, you may escalate your complaint with details of the
            Services availed by you to our grievance officer at [insert e-mail]
            or [insert phone number]. The relevant grievance officer shall
            respond to your complaint or grievance within 7 business days of
            receiving the communication failing which you may choose to resolve
            your complaint or grievance pursuant to Clause 12.3 below.]
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              12.2
            </Text>
            You acknowledge and agree that it is preferable to resolve all
            disputes between you and the Company confidentially, individually
            and in an expeditious and inexpensive manner. You and the Company
            acknowledge and agree that private dispute resolution is preferable
            to court actions. Before commencing any formal dispute resolution in
            the form of arbitration proceedings, you and the Company shall first
            attempt to resolve any dispute or differences between them by way of
            good faith negotiation in accordance with Clause 12.1 above.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              12.3
            </Text>
            Upon failure of good faith negotiations in accordance with Clause
            12.1 above, the disputes arising out of or relating to the Services,
            or Terms shall be resolved exclusively by final and binding
            arbitration by a sole arbitrator in accordance with the Arbitration
            and Conciliation Act, 1996 for the time being in force, which is
            deemed to be incorporated by reference in this clause. The seat and
            venue for the arbitration shall be Bengaluru. The decision of the
            arbitrator shall be final and binding on both the Parties. Each
            party to the arbitration shall bear its own costs with respect to
            any dispute{" "}
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              12.4
            </Text>
            You agree and acknowledge that the Company shall be entitled to
            raise a dispute or a claim against you for settlement of dues from
            such third parties who are involved in the provision of the Services
            to you.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            13. FORCE MAJEURE
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <Text as="span" styleguide="body1" color="gray" mr="md">
              13.1
            </Text>
            The Company shall have no liability to you if Company is prevented
            from, or delayed in performing its obligations, or from carrying on
            its Business, by acts, events, omissions, or accidents beyond its
            reasonable control, including without limitation, pandemic, strikes,
            failure of a utility service or telecommunications network, act of
            God, war, riot, civil commotion, malicious damage, or compliance
            with any law or governmental order, rule, regulation, or direction.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14. MISCELLANEOUS PROVISIONS
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.1 Changes to Terms.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            The Terms are subject to revision at any time, as determined by the
            Company which inter-alia may include revision in Security Deposit,
            Minimum Amount, shipping fee or any other fee or reimbursements, and
            the cash outflow / settlement rules. Once posted on the Platform,
            you shall be required to review the Terms and give express consent
            by clicking on{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              “I Agree”
            </Text>
            . The updated Terms shall be effective once you provide express
            consent.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.2 Modification to the Services.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            The Company reserves the right at any time to add, modify, or
            discontinue (temporarily or permanently), the Services (or any part
            thereof), with or without cause. The Company shall not be liable for
            any such addition, modification, suspension, or discontinuation of
            the Services.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.3 Notices.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            All notices, requests, demands, and determinations under these Terms
            (other than routine operational communications) shall be sent by you
            to
            <Text as="div" styleguide="body1" color="gray" mt="xl">
              Address:
            </Text>
            <Text as="div" styleguide="body1" color="gray" mt="sm">
              Blitzscale Technology Solutions Pvt Ltd.
            </Text>
            <Text as="div" styleguide="body1" color="gray" >
              Indiqube Alpha, Plot No. 19/4, 27, Outer Ring Road, Panathur
              Junction, Bengaluru, Karnataka 560103, India
            </Text>
            <Text as="span" styleguide="body1" color="gray" mr="md">
              Email:
            </Text>
            <Link relative color="tertiary" underline target="_blank">
              customercare@blitzscale.com
            </Link>
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.4 Third Party Rights.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            No third party shall have any right to enforce any terms contained
            herein.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.5 Entire Terms and Severability.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            <ul className={cx("no-list-style-type")}>
              <li>
                <Text as="div" styleguide="body1" color="gray" mt="xl">
                  <Text as="span" styleguide="body1" color="gray" mr="md">
                    14.5.1
                  </Text>
                  These Terms constitute the entire contract between the Parties
                  with respect to the subject matter hereof and supersedes all
                  prior or contemporaneous written or oral understandings,
                  agreements and communications between them with respect to
                  such subject matter.
                </Text>
              </li>

              <li>
                <Text as="div" styleguide="body1" color="gray" mt="xl">
                  <Text as="span" styleguide="body1" color="gray" mr="md">
                    14.5.2
                  </Text>
                  If any portion of these Terms shall be held invalid or
                  unenforceable for any reason, the remaining provisions shall
                  continue to be valid and enforceable. If a court finds that
                  any provision of this Terms is invalid or unenforceable, but
                  that by limiting such provision, it would become valid and
                  enforceable, then such provision shall be deemed to be
                  written, construed, and enforced as so limited.
                </Text>
              </li>
            </ul>
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.6 Relationship Between Parties.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            Nothing in these Terms, is intended to, or shall be deemed to create
            a relationship of partnership, agency, joint venture, joint
            enterprise, or fiduciary relationship between the Parties, and
            neither Party shall have the authority to contract for, or enter
            into commitments, for, or on behalf of the other Party.
          </Text>
        </li>

        <li>
          <Text
            styleguide="heading3"
            color="tertiary"
            fontWeight="bold"
            mt="xl"
          >
            14.7 Execution.
          </Text>
        </li>

        <li>
          <Text as="div" styleguide="body1" color="gray" mt="xl">
            These Terms shall be deemed to be executed upon you clicking{" "}
            <Text
              as="span"
              styleguide="body1"
              fontWeight="bold"
              color="tertiary"
            >
              "I Agree"
            </Text>
            .
          </Text>
        </li>
      </ul>
    </div>
  );
};

export default Terms;
