// extracted by mini-css-extract-plugin
var _1 = "DisplayCard-module__amount-paid-flex___qpvJg";
var _2 = "DisplayCard-module__amount-paid-value___pWJ9O";
var _3 = "DisplayCard-module__avatar-card-container___DgTC7";
var _4 = "DisplayCard-module__body1___m1FtA";
var _5 = "DisplayCard-module__body1-sm___RV00T";
var _6 = "DisplayCard-module__body2___oOY4w";
var _7 = "DisplayCard-module__body2-sm___xWzPg";
var _8 = "DisplayCard-module__body3___VotQb";
var _9 = "DisplayCard-module__body3-sm___pxeqd";
var _a = "DisplayCard-module__body4___FVWqd";
var _b = "DisplayCard-module__body4-sm___H3Wf3";
var _c = "DisplayCard-module__display-card-border___RIF6D";
var _d = "DisplayCard-module__display-card-container___BJjJ3";
var _e = "DisplayCard-module__heading1-sm___bn2_G";
var _f = "DisplayCard-module__heading2-sm___SurAx";
var _10 = "DisplayCard-module__heading3-sm___sa6n4";
var _11 = "DisplayCard-module__heading4-sm___n90bF";
var _12 = "DisplayCard-module__heading5-sm___o273R";
var _13 = "DisplayCard-module__heading6-sm___Cjayo";
var _14 = "DisplayCard-module__seller-info-row___p4pAW";
export { _1 as "amount-paid-flex", _2 as "amount-paid-value", _3 as "avatar-card-container", _4 as "body1", _5 as "body1-sm", _6 as "body2", _7 as "body2-sm", _8 as "body3", _9 as "body3-sm", _a as "body4", _b as "body4-sm", _c as "display-card-border", _d as "display-card-container", _e as "heading1-sm", _f as "heading2-sm", _10 as "heading3-sm", _11 as "heading4-sm", _12 as "heading5-sm", _13 as "heading6-sm", _14 as "seller-info-row" }
