// extracted by mini-css-extract-plugin
var _1 = "TrustedBy-module__brand-mgsv-company-trusted-by___DcsvN";
var _2 = "TrustedBy-module__joggers-park-company-trusted-by___Ffyvg";
var _3 = "TrustedBy-module__logoloop___B7q5N";
var _4 = "TrustedBy-module__om-gayatri-corp-company-trusted-by___CXdg9";
var _5 = "TrustedBy-module__owl-item___fa1hd";
var _6 = "TrustedBy-module__serene-silk-company-trusted-by___lpJsb";
var _7 = "TrustedBy-module__street-mode-company-trusted-by___Rb8Cx";
var _8 = "TrustedBy-module__trusted-by-carousel___O2400";
var _9 = "TrustedBy-module__trusted-by-carousel-item___t5VyP";
var _a = "TrustedBy-module__trusted-by-carousel-scroller___JAQpC";
var _b = "TrustedBy-module__urban-cultry-company-trusted-by___fNwFy";
var _c = "TrustedBy-module__vihaa-jewellery-company-trusted-by___glMnV";
var _d = "TrustedBy-module__widget-everything-you-need-to-start___suhib";
export { _1 as "brand-mgsv-company-trusted-by", _2 as "joggers-park-company-trusted-by", _3 as "logoloop", _4 as "om-gayatri-corp-company-trusted-by", _5 as "owl-item", _6 as "serene-silk-company-trusted-by", _7 as "street-mode-company-trusted-by", _8 as "trusted-by-carousel", _9 as "trusted-by-carousel-item", _a as "trusted-by-carousel-scroller", _b as "urban-cultry-company-trusted-by", _c as "vihaa-jewellery-company-trusted-by", _d as "widget-everything-you-need-to-start" }
