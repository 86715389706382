import React, { useLayoutEffect } from "react";
import classNames from "classnames/bind";
import * as style from "./EverythingYouNeed.module.scss";
import OwlCarousel from "../../atoms/OwlCarousel";
import Button from "../../atoms/Button";
import Text from '../../atoms/Typography';
import Flexbox from "../../atoms/Flexbox";

const cx = classNames.bind(style);

const EverythingYouNeed = ({ handleBooking }) => {

  return (
    <div
      className={cx(
        "widget",
        "widget-everything-you-need-to-start",
        "without-background"
      )}
    >
      <Text styleguide="heading1" styleguideSm="heading3-sm" fontWeight="bold" align="center" mb="md">
        Everything You Need To Start And Succeed
      </Text>
      <Text styleguide="body1" styleguideSm="body2-sm" align="center" mb="xl" color="gray">
        The All-in-One Platform to Create, Manage, and Grow Your Online Store
      </Text>
      {/* <div className={cx("mb-xxl", "text-center", "mb-xl")}>
        <Button appearance="tertiary" onClick={handleBooking}>
          Book a free Demo
        </Button>
      </div> */}
      <Flexbox mb="xl" direction="row" justify="center">
        <Button size="md" appearance="tertiary" onClick={handleBooking}>
          Book a free Demo
        </Button>
      </Flexbox>
      <OwlCarousel owlSelector="everything-you-need-carousel">
        <div className={cx("everything-you-need-carousel-item")}>
          <div className={cx("icon-container", "website-icon", "mb-xl")}></div>
          <Text styleguide="heading4" align="center" mb="md" mt="sm">
            Website
          </Text>
          <Text styleguide="body2" align="center" color="gray">
            Create a website in 30 Mins, no coding or design skills needed.
            Customise your website with your branding and products.
          </Text>
        </div>
        <div className={cx("everything-you-need-carousel-item")}>
          <div className={cx("icon-container", "shipping-icon", "mb-xl")}></div>
          <Text styleguide="heading4" align="center" mb="md" mt="sm">
            Shipping
          </Text>
          <Text styleguide="body2" align="center" color="gray">
            Integrated shipping system at most affordable pricing, starts from
            Rs.49. Grow your business by enabling cash on delivery for your
            customers.
          </Text>
        </div>
        <div className={cx("everything-you-need-carousel-item")}>
          <div className={cx("icon-container", "pricing-icon", "mb-xl")}></div>
          <Text styleguide="heading4" align="center" mb="md" mt="sm">
            Pricing
          </Text>
          <Text styleguide="body2" align="center" color="gray">
            Get expert guidance and support to help you implement the best
            pricing strategies for maximum Profits
          </Text>
        </div>
        <div className={cx("everything-you-need-carousel-item")}>
          <div
            className={cx("icon-container", "marketing-icon", "mb-xl")}
          ></div>
          <Text styleguide="heading4" align="center" mb="md" mt="sm">
            Marketing
          </Text>
          <Text styleguide="body2" align="center" color="gray">
            Run ads on Facebook, Google, Instagram at 0% commission and get the
            best ROI.
          </Text>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default EverythingYouNeed;
