import React from "react";
// import { Link } from "react-router-dom";
import * as styles from "./Footer.module.scss";
import classNames from "classnames/bind";
import Link from "../../atoms/Link/Link";
import Text from "../../atoms/Typography";

const cx = classNames.bind(styles);

const Footer = (): JSX.Element => {
  return (
    <footer className={cx("widget-wrapper")}>
      <div className={cx("widget", "footer")}>
        <div className={cx("main-footer", "mb-xxl")}>
          <div className={cx("about-shopdeck", "mb-xxl")}>
            <img
              className={cx("mb-lg")}
              alt="ShopDeck-logo"
              src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/5c5f7711-549e-4c56-b5f8-e0a25c6d78fc.svg"
            />
            <Text styleguide="body2" color="white">
              Businesses from all over India use ShopDeck to manage their business and sell online.
            </Text>

            <div className={cx("contact-details")}>
              <div className={cx("contact-details-item")}>
                <div className={cx("footer-contact-item", "email")}></div>
                <Text styleguide="body2" color="white">admin@blitzscale.co</Text>
              </div>
              <div className={cx("contact-details-item")}>
                <div className={cx("footer-contact-item", "location")}></div>
                <Text styleguide="body2" color="white">
                IndiQube Alpha, 1st floor, Marathahalli Panathur Junction, Plot No.19/4 27, Outer Ring Rd, Kadubeesanahalli, Panathur, Bengaluru, Karnataka 560103
                </Text>
              </div>
            </div>
          </div>
          <div className={cx("hero-links")}>
            <Text styleguide="heading3" color="white" mb="lg">
              <Link to="/#testimonials">
                Testimonials
              </Link>
            </Text>

            <Text styleguide="heading3" color="white" mb="lg">
              <Link to="/#features">
                Features
              </Link>
            </Text>

            <Text styleguide="heading3" color="white" mb="lg">
              <Link mb="lg" to="/#pricing">
                Pricing
              </Link>
            </Text>

            <Text styleguide="heading3" color="white" mb="lg">
              <Link
                relative
                to="https://pro.shopdeck.com"
                target="_blank"
                color="white"
              >
                Login
              </Link>
            </Text>

          </div>
          <div className={cx("aux-links")}>
            <Text styleguide="body3" color="white" mb="md">
              <Link to="/privacyPolicy">
                Privacy Policy
              </Link>
            </Text>

            <Text styleguide="body3" color="white" mb="md">
              <Link to="/terms">
                Terms and Conditions
              </Link>
            </Text>

            <Text styleguide="body3" color="white" mb="md">
              <Link to="/careers">
                Careers
              </Link>
            </Text>

          </div>
        </div>
        <div className={cx("copyright-subfooter")}>
          <Text styleguide="body3" color="white" mb="md">
            © Copyright ShopDeck.com
          </Text>
        </div>
      </div>
    </footer >
  );
};

export default Footer;

