import React from "react";
import { DividerProps } from "./types";
import { Divider } from "rsuite";
import { PropsWithChildren } from "react";
import classNames from "classnames/bind";
import * as style from "./Divider.module.scss";
const cx = classNames.bind(style);

const DividerComponent = (
  props: PropsWithChildren<DividerProps>
): JSX.Element => {
  const { spacing, color = "gray300", ml, mr, mt, mb, className, ...rest } = props;

  return (
    <Divider
      className={cx([
        `default`,
        `${color ? `${color}-colored` : ``}`,
        `${ml ? `ml-${ml}` : ""}`,
        `${mr ? `mr-${mr}` : ""}`,
        `${mt ? `mt-${mt}` : ""}`,
        `${mb ? `mb-${mb}` : ""}`,
        className,
      ])}
      {...rest}
    />
  );
};

export default DividerComponent;
