import React, { PropsWithChildren } from "react";
import { TextProps } from "./types";
import * as style from "./Text.module.scss";
import classNames from "classnames/bind";
import { Whisper } from "rsuite";
const cx = classNames.bind(style);


const styleguideToHTMLMapper = {
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  heading5: "h5",
  heading6: "h6",
  body1: "p",
  body2: "p",
  body3: "p",
  body4: "p"
};

const Text = (props: PropsWithChildren<TextProps>): JSX.Element => {
  let {
    as,
    color,
    className,
    styleguide,
    styleguideSm,
    children,
    underline = false,
    strikeThrough = false,
    mt,
    mb,
    ml,
    mr,
    ellipsis = false,
    copiable,
    breakWord,
    textTransform,
    align,
    fontWeight,
    ...rest
  } = props;


  const As = as
    ? as
    : styleguideToHTMLMapper[styleguide] || ("p" as React.ElementType);

	const classes = cx([
		styleguide,
		styleguideSm,
		fontWeight,
		`${color ? `${color}-colored` : ""}`,
		`${underline ? "underline" : ""}`,
		`${strikeThrough ? "strike-through" : ""}`,
		`${ml ? `ml-${ml}` : ""}`,
		`${mr ? `mr-${mr}` : ""}`,
		`${mt ? `mt-${mt}` : ""}`,
		`${mb ? `mb-${mb}` : ""}`,
		`${ellipsis ? "ellipsis" : ""}`,
		`${copiable ? "cursor-pointer" : ""}`,
		`${breakWord ? "break-word" : ""}`,
		`${textTransform ? `text-transform-${textTransform}` : ""}`,
		`${align ? `text-align-${align}` : ""}`,
		className,
	]);

  const Child = (
    <As
      className={classes}
      {...rest}
    >
      {children}
    </As>
  );

  return Child;
};

export default Text;
