import React from 'react';
import classNames from "classnames/bind";
import * as style from "./TrustedBy.module.scss";
import Text from '../../atoms/Typography';
const cx = classNames.bind(style);

const TrustedBy = () => {

    return (
        <div className={cx("widget", "widget-trusted-by", "without-background")}>
            <Text styleguide="heading1" styleguideSm="heading3-sm" fontWeight='bold' align='center' mb='xl'>
                Trusted by 5000+ brands
            </Text>
            <div className={cx("trusted-by-carousel")}>
                <div className={cx("trusted-by-carousel-scroller")}>
                    <div className={cx("trusted-by-carousel-item", "street-mode-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "om-gayatri-corp-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "joggers-park-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "urban-cultry-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "brand-mgsv-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "vihaa-jewellery-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "serene-silk-company-trusted-by")}></div>
                </div>
                <div className={cx("trusted-by-carousel-scroller")}>
                    <div className={cx("trusted-by-carousel-item", "street-mode-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "om-gayatri-corp-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "joggers-park-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "urban-cultry-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "brand-mgsv-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "vihaa-jewellery-company-trusted-by")}></div>
                    <div className={cx("trusted-by-carousel-item", "serene-silk-company-trusted-by")}></div>
                </div>
            </div>
        </div>
    )
}

export default TrustedBy;
