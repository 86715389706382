import classNames from "classnames/bind";
import React, { useLayoutEffect, useState } from "react";
import BookDemo from "../../atoms/BookDemo";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import TermsCondition from "../../molecules/TermsCondition";
import * as styles from "./Terms.module.scss";
import Loader from "../Loader";
import useQuery from "../../hooks/useQuery";
import { fireDemoFormEvent } from "../../events/bookDemoForm";
import { useNavigate } from "react-router-dom"

const cx = classNames.bind(styles);

const Terms = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();
  const showOnlyTerms = query.get("show_only_terms");
  const navigate = useNavigate();

  useLayoutEffect(() => setIsLoading(false), []);

  const handleBooking = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    fireDemoFormEvent();
    navigate("/book-demo-form");
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />
  }

  if (showOnlyTerms) return <TermsCondition />

  return (
    <main>
      <Navbar handleBooking={handleBooking} />

      <section className={cx("widget-wrapper-home")}>
        <TermsCondition />
      </section>
      <Footer />
      <BookDemo handleBooking={handleBooking} />
    </main>
  );
};

export default Terms;
