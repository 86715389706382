import classNames from "classnames/bind";
import React, { useLayoutEffect, useState } from "react";
import Navbar from "../../molecules/Navbar";
import Loader from "../Loader";
import Greetings from "../../molecules/Greetings";
import { useNavigate } from "react-router-dom"
import { fireDemoFormEvent } from "../../events/bookDemoForm";

const ThankYou = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	useLayoutEffect(() => setIsLoading(false), []);

	const handleBooking = (ev: React.MouseEvent<HTMLElement>) => {
		ev.preventDefault();
		fireDemoFormEvent();
		navigate("/book-demo-form");
	};
	if (isLoading) {
		return <Loader isLoading={isLoading} />
	}

	return (
		<main>
			<Navbar handleBooking={handleBooking} />
			<Greetings />
		</main>

	);
};

export default ThankYou;
