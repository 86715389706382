import React from "react";
import { Link } from "react-router-dom";
import { LinkComponentProps } from "./types";

import classNames from "classnames/bind";
import * as style from "./Link.module.scss";

const cx = classNames.bind(style);

const LinkComponent = (props: LinkComponentProps): JSX.Element => {
  let { as, relative = false, children, color, target, underline, className, ...rest } = props;
  if (relative)
    return (
      <a
        className={cx([
          "nav",
          `${color ? `${color}-colored` : ""}`,
          `${underline ? "underline" : ''}`,
          className
        ])}
        href={rest.to as string}
        target={target}
        {...rest}
      >
        {children}
      </a>
    );
  return (
    <Link
      className={cx([
        "nav",
        `${color ? `${color}-colored` : ""}`,
        `${underline ? "underline" : ''}`,
        className
      ])}
      children={children}
      {...rest}
    />
  );
};

export default LinkComponent;
