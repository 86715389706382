import React from "react";
import { ImageProps } from "./types";

const Image = (props: ImageProps): JSX.Element => {
  const { src, alt, ...rest } = props;

  const cloudFrontSrc = React.useMemo(() => {
    if (!src) return "";
    // return getCloudFrontUrlFromS3Url(src);
    return src;
  }, [src]);

  return <img src={cloudFrontSrc} alt={alt} {...rest} />;
};

export default Image;
