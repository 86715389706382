// extracted by mini-css-extract-plugin
var _1 = "Card-module__card___uckKu";
var _2 = "Card-module__mb-lg___PSIMR";
var _3 = "Card-module__mb-md___bUZmE";
var _4 = "Card-module__mb-sm___o25CL";
var _5 = "Card-module__mb-xl___fce0u";
var _6 = "Card-module__mb-xs___mLxB6";
var _7 = "Card-module__mb-xxl___LZcPS";
var _8 = "Card-module__ml-lg___aXxEb";
var _9 = "Card-module__ml-md___wsk1C";
var _a = "Card-module__ml-sm___KyXoG";
var _b = "Card-module__ml-xl___zFaLA";
var _c = "Card-module__ml-xs___nZ93a";
var _d = "Card-module__ml-xxl___VGHE2";
var _e = "Card-module__mr-lg___tdxQV";
var _f = "Card-module__mr-md___RPBi5";
var _10 = "Card-module__mr-sm___o6E0E";
var _11 = "Card-module__mr-xl___DfSIH";
var _12 = "Card-module__mr-xs___mZFth";
var _13 = "Card-module__mr-xxl___eNfs7";
var _14 = "Card-module__mt-lg___T2bqb";
var _15 = "Card-module__mt-md____juKR";
var _16 = "Card-module__mt-sm___hYvHO";
var _17 = "Card-module__mt-xl___gt1m2";
var _18 = "Card-module__mt-xs___rBPmF";
var _19 = "Card-module__mt-xxl___pkPjA";
var _1a = "Card-module__no-horizontal-padding___a5QwK";
var _1b = "Card-module__no-padding___d85Jv";
var _1c = "Card-module__no-vertical-padding___UJ1Zq";
export { _1 as "card", _2 as "mb-lg", _3 as "mb-md", _4 as "mb-sm", _5 as "mb-xl", _6 as "mb-xs", _7 as "mb-xxl", _8 as "ml-lg", _9 as "ml-md", _a as "ml-sm", _b as "ml-xl", _c as "ml-xs", _d as "ml-xxl", _e as "mr-lg", _f as "mr-md", _10 as "mr-sm", _11 as "mr-xl", _12 as "mr-xs", _13 as "mr-xxl", _14 as "mt-lg", _15 as "mt-md", _16 as "mt-sm", _17 as "mt-xl", _18 as "mt-xs", _19 as "mt-xxl", _1a as "no-horizontal-padding", _1b as "no-padding", _1c as "no-vertical-padding" }
