import React from "react";
// import { Link } from "react-router-dom";
import { LandingModalProps } from "./types";
import classNames from "classnames/bind";
import * as style from "./LandingModal.module.scss";
import Link from '../Link';

const cx = classNames.bind(style);

const LandingModal = (props: LandingModalProps): JSX.Element => {
  const { closeLandingModal } = props;
  return (
      <div className={cx("modal-wrapper")}>
        <div className={cx("modal-header")}>
          <img
            alt="ShopDeck-logo"
            src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/a7ca3d39-815f-4007-a229-52762925ccbd.svg"
          />
          <span className={cx("close-icon")} onClick={closeLandingModal}>
            &times;
          </span>
        </div>
        <div className={cx("modal-content")}>
          <Link
            to="/#pricing"
            color="tertiary"
            onClick={closeLandingModal}
          >
            Pricing
          </Link>
          <Link
            to="/#features"
            color="tertiary"
            onClick={closeLandingModal}
          >
            Features
          </Link>
          <Link
            relative
            to="http://pro.shopdeck.com/"
            target="_blank"
            color="tertiary"
            onClick={closeLandingModal}
          >
            Login
          </Link>
        </div>
      </div>
  );
};

export default LandingModal;
