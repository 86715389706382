// extracted by mini-css-extract-plugin
var _1 = "Testimonials-module__brand-mgsv-company___waEGJ";
var _2 = "Testimonials-module__joggers-park-company___XaN5G";
var _3 = "Testimonials-module__om-gayatri-corp-company___P10Wj";
var _4 = "Testimonials-module__serene-silk-company___LYOkN";
var _5 = "Testimonials-module__street-mode-company___uHt5o";
var _6 = "Testimonials-module__testimonial-carousel-item___wdz1b";
var _7 = "Testimonials-module__testimonial-carousel-item-company-logo___gXf7N";
var _8 = "Testimonials-module__urban-cultry-company___YJtsx";
var _9 = "Testimonials-module__vihaa-jewellery-company___uHeMW";
export { _1 as "brand-mgsv-company", _2 as "joggers-park-company", _3 as "om-gayatri-corp-company", _4 as "serene-silk-company", _5 as "street-mode-company", _6 as "testimonial-carousel-item", _7 as "testimonial-carousel-item-company-logo", _8 as "urban-cultry-company", _9 as "vihaa-jewellery-company" }
