import React from 'react';
import classNames from "classnames/bind";
import * as style from "./Reachout.module.scss";
import Text from '../../atoms/Typography';

const cx = classNames.bind(style);

const Reachout = () => {
    return (
        <div className={cx("widget", "widget-ctm", "without-background", "text-center")}>
            <div className={cx("ctm-media-container")}>
                <img
                    alt="ShopDeck-Features"
                    src="https://cdn.zeplin.io/62ec03b0fbeada751f8a5998/assets/5dd92b10-b44e-493f-b731-c7d51ff57dd9.png"
                />
            </div>
            <div className={cx("ctm-content-container")}>
                <Text styleguide='body1' color='tertiary'>
                    A representative will reach out to you and help set up your online
                    platform. Your dashboard will be set up after which the marketing
                    for your platform will start.
                </Text>
                <Text styleguide='heading4' color="tertiary">
                    You can contact us at{" "}
                    <Text as="span" styleguide='heading4' color='primary'>onboarding@blitzscale.co</Text>
                </Text>
            </div>
        </div>
    )
}

export default Reachout;
