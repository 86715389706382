import React, { useRef, useState } from "react";
//Atoms
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import LandingModal from "../../atoms/LandingModal";
//Styles
import * as styles from "./Navbar.module.scss";
import classNames from "classnames/bind";
//Types
import { NavbarProps } from "./types";

const cx = classNames.bind(styles);

const RightLayoutForRegistrationPage = (props) => {
  const { isSuccessScreen, initials } = props;

  return (
    <div>
      {
        isSuccessScreen && <div className={cx("nav-item-avatar")}> {initials}</div>
      }
      {
        !isSuccessScreen &&
        <Button
          onClick={() => { }}
          appearance="primary"
          className={cx("hidden-mobile")}
        >
          Register & Pay Deposit
        </Button>
      }
    </div >
  )
}

const Navbar = (props: NavbarProps): JSX.Element => {
  const { handleBooking, isRegistrationPages = false, isSuccessScreen = false, initials } = props;
  const [isModal, setIsModal] = useState(false);
  const modalRef = useRef<HTMLInputElement>(null);

  const openModal = () => {
    if (modalRef.current) 
      if (modalRef.current) {
        modalRef.current.style.height = '100%';
        modalRef.current.style.zIndex = '35';
    }
    setIsModal(true)
  };
  const closeLandingModal = () => {
    if (modalRef.current) modalRef.current.style.height = '0%';
    setIsModal(false)
  };

  return (
    <>
      <section className={cx("widget-wrapper", "sticky-navbar")}>
        <div className={cx("widget", "without-background", "widget-navbar")}>
          <nav className={cx("navbar")}>
            <Link to="/" className={cx("logo")}>
              <img
                alt="ShopDeck-logo"
                src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/a7ca3d39-815f-4007-a229-52762925ccbd.svg"
              />
            </Link>
            <div className={cx("nav-items")}>
              {
                !isRegistrationPages ? <>
                  <Link to="/#pricing" color="tertiary">
                    Pricing
                  </Link>
                  <Link to="/#features" color="tertiary">
                    Features
                  </Link>
                  <Link
                    to="http://pro.shopdeck.com/"
                    target="_blank"
                    relative={true}
                    color="tertiary"
                  >
                    Login
                  </Link>
                  <Button
                    onClick={handleBooking}
                    appearance="primary"
                    className={cx("hidden-mobile")}
                  >
                    Book a free Demo
                  </Button>
                  <button
                    className={cx("button-icon", "hidden-desktop", "hamburger-menu")}
                    onClick={openModal}
                  >
                    <span className={cx("hamburger-menu-icon")}></span>
                  </button>
                </> :
                  < RightLayoutForRegistrationPage isSuccessScreen={isSuccessScreen} initials={initials} />
              }

            </div>
          </nav>
        </div >
      </section>
      <div ref={modalRef} className={cx("modal")}>
        {isModal && <LandingModal closeLandingModal={closeLandingModal} />}
      </div>
    </>
  );
};

export default Navbar;
