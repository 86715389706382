// extracted by mini-css-extract-plugin
var _1 = "Link-module__gray-colored___I7ivc";
var _2 = "Link-module__gray300-colored___I4nlb";
var _3 = "Link-module__green-colored___q8Cqp";
var _4 = "Link-module__nav___D8MAw";
var _5 = "Link-module__primary-colored___lsbAf";
var _6 = "Link-module__red-colored___Y_nVE";
var _7 = "Link-module__secondary-colored___SB_1M";
var _8 = "Link-module__tertiary-colored___yWHEa";
var _9 = "Link-module__underline___NNK9i";
var _a = "Link-module__white-colored___XE3yS";
export { _1 as "gray-colored", _2 as "gray300-colored", _3 as "green-colored", _4 as "nav", _5 as "primary-colored", _6 as "red-colored", _7 as "secondary-colored", _8 as "tertiary-colored", _9 as "underline", _a as "white-colored" }
