import React from "react";
import { DisplayCardProps } from "./types";
import Card from "../Card";
import Text from "../Typography";
import Divider from "../Divider";
import Flexbox from "../Flexbox";
import Image from "../Image";
import * as style from "./DisplayCard.module.scss";
import classNames from "classnames/bind";
import { getInitials } from "../utlis";

const cx = classNames.bind(style);

const DisplayCard = (props: DisplayCardProps): JSX.Element => {
  const {
    name,
    amountPaid,
    sellerId,
    phoneNumber,
    brandName,
    gstNumber,
    transactionId,
  } = props;
  let avatarName = getInitials(name);
  let data = [
    ["Seller ID", sellerId],
    ["Phone Number", phoneNumber],
    ["Brand Name", brandName],
    ["GST Number", gstNumber],
    ["Transaction Id", transactionId],
  ];
  return (
    <>
      <Card className={cx("display-card-border")}>
        <Card className={cx("display-card-container")}>
          <Text styleguide="heading2">Registration Details</Text>
          <Divider mt="xxl" mb="xxl" />
          <Card className={cx("avatar-card-container")} mb="sm">
            <Text
              styleguide="body2"
              color="white"
              className={cx("avatar-text")}
            >
              {avatarName}
            </Text>
          </Card>
          <Text styleguide="body2" mb="xl" mt="xs" color="tertiary">
            {name}
          </Text>
          <Text styleguide="heading3" className={cx("amount-paid-value")}>
            ₹ {amountPaid}/-
          </Text>
          <Flexbox direction="row" className={cx("amount-paid-flex")}>
            <Flexbox.Item>
              <Image src="https://cdn.zeplin.io/62ec03b0fbeada751f8a5998/assets/c39e1251-dc23-4d14-a332-7c5ccf233f31.svg" />
            </Flexbox.Item>
            <Flexbox.Item>
              <Text styleguide="body3" color="green">
                Amount Paid for Registration
              </Text>
            </Flexbox.Item>
          </Flexbox>

          <Divider mt="xxl" mb="xxl" />
          {data.map(([key, value]) => (
            <Flexbox
              key={`row_${key}`}
              direction="row"
              justify="space-between"
              mb="md"
              className={cx("seller-info-row")}
            >
              <Flexbox.Item>
                <Text styleguide="body2" color="gray">{key}</Text>
              </Flexbox.Item>
              <Flexbox.Item>
                <Text styleguide="body2" color="tertiary">
                  {value}
                </Text>
              </Flexbox.Item>
            </Flexbox>
          ))}
        </Card>
      </Card >
    </>
  );
};
export default DisplayCard;
