import { push } from "../services/sendEvent";

export const fireDemoFormEvent = () => {
	// Call push function with event-label "lead_form_cta_clicked" and required payload
	push({
		event_label: "lead_form_cta_clicked",
		event_datum: {
			field_information: [""]
		},
		visitor_id: "",
		session_id: "",
		language: "",
		useragent: "",
		urlslug: "",
		platform: ""
	});
}
