import React from "react";
import Text from "../../atoms/Typography";
import * as styles from "./Privacy.module.scss";
import classNames from "classnames/bind";
import Link from "../../atoms/Link";

const cx = classNames.bind(styles);

const Privacy = (): JSX.Element => {
	return (
		<div className={cx("widget", "widget-privacy-policy")}>
			<Text
				styleguide="heading1"
				styleguideSm="heading3-sm"
				color="tertiary"
				mb="md"
				fontWeight="bold"
				align="center"
			>
				PRIVACY POLICY
			</Text>
			<Text styleguide="body1" color="gray">
				Effective date: 16th July 2024
			</Text>
			<Text styleguide="body1" color="gray">
				Blitzscale Technology Solutions Private Limited {""} (
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					“ShopDeck”, "Company", "us", "we", or "our"
				</Text>
				) {""} values your privacy. We may become privy to some Personal
				Information (as defined below) provided by individuals {""} (
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					“users”, “you” or “your”
				</Text>
				<Text as="span" styleguide="body1">
					), including information which may or may not be confidential in
					nature. In this Privacy Policy (
					<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
						"Policy"
					</Text>
					<Text as="span" styleguide="body1">
						), the Company describes the information that it collects about you
						when you visit the Company’s platform and/or use the Services (as
						defined under the Terms and Conditions) available on or through {""}
					</Text>
					<Link
						color="gray"
						relative
						underline
						to="https://shopdeck.com/"
						target="_blank"
					>
						https://shopdeck.com/
					</Link>{" "}
				</Text>
				<Text as="span" styleguide="body1">
					and related domains including but not limited to {""}
				</Text>
				<Link
					color="gray"
					relative
					underline
					to="https://pro.shopdeck.com/"
					target="_blank"
				>
					https://pro.shopdeck.com/
				</Link>{" "}
				<Text as="span" styleguide="body1">
					and/or the Company’s mobile app (collectively the {""}
				</Text>
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					"Platform"
				</Text>
				<Text as="span" styleguide="body1">
					), and how the Company uses and discloses such Personal Information.
				</Text>
			</Text>
			<Text styleguide="body1" color="gray">
				For all intents and purposes of this Policy, Personal Information shall
				mean any information provided by you and/or available with the Company,
				which helps the Company to identify you. However, no such information
				which is already available in public domain, or which can be furnished
				under Right to Information Act, 2005 or any law in force in India shall
				be considered as personal information under this Policy (
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					“Personal Information”
				</Text>
				). Personal Information may include email address, phone number,
				business name, your first and last name, Cookies (as defined below) and
				Usage Data (as defined below).
			</Text>

			<Text styleguide="body1" color="gray">
				By using the, Services you agree and consent to the Company’s use of
				your Personal Information (including sensitive personal information) in
				accordance with this Policy, as may be amended from time to time by the
				Company at its discretion. You also agree and consent to the Company
				collecting, storing, processing, transferring, and sharing your Personal
				Information (including sensitive personal information) with third
				parties or service providers for the Purposes set out in this Policy.
			</Text>

			<Text styleguide="body1" color="gray">
				This Policy shall be applicable to:
			</Text>

			<ol type="a" style={{ listStyleType: "none" }}>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(a) &nbsp;You if you voluntarily provide such information, such as
						when you contact us with inquiries, register for access to the
						Platform or purchase products or Services from the Platform;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(b) &nbsp;You every time you visit / access / use our Platform, when
						we collect information about your interaction with our Platform,
						such as pages visited and average time spent on the Platform, your
						internet protocol address, the unique identifier of your device and
						device information such as operating system, operating system
						version, browser plugins, cache, system activity, hardware settings,
						date and time of Your request and referral URL, and cookies that may
						uniquely identify your browser, browser data, mobile application and
						mobile information. We use this data to analyse how you use our
						Platform and improve the Services provided through the Platform;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(c) &nbsp;all merchants using our Platform to avail our Services;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(d) &nbsp;all end customers who make purchases from the merchants
						using our Services; and
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(e) &nbsp;all partners who develop applications for merchants,
						manage merchant portfolios, refer potential merchants to ShopDeck,
						or otherwise assist merchants in operating or enhancing their
						ShopDeck-powered business.
					</Text>
				</li>
			</ol>

			<Text styleguide="body1" color="tertiary" underline fontWeight="bold">
				DEFINITIONS
			</Text>

			<Text styleguide="body1" color="gray">
				Usage Data: Usage Data is data collected automatically either generated
				by the use of the Services or from the service infrastructure itself.
			</Text>

			<Text styleguide="body1" color="gray">
				Cookies: Cookies are small files stored on user’s device (computer or
				mobile device) containing information about the user.
			</Text>

			<Text styleguide="body1" color="tertiary" underline fontWeight="bold">
				USAGE DATA
			</Text>

			<Text styleguide="body1" color="gray">
				The Company and its third-party service providers, which include ad
				networks, use cookies, web beacons, and other tracking technologies to
				collect information about your use of ShopDeck, such as your browser
				type, your ISP or operating system, your domain name, your access time,
				the URL of the previous website you visited, your page views, your IP
				address, and the type of device that you use. The Company also tracks
				how frequently you visit ShopDeck. The Company uses this information
				(including the information collected by its third-party service
				providers) for website analytics (including to determine which portions
				of ShopDeck are used most frequently, what the Company’s users like / do
				not like), to assist it in determining relevant advertising (both on and
				off ShopDeck), to evaluate the success of the Company’s advertising
				campaigns, and as otherwise described in this Policy. Currently, the
				Company does not honour browser requests not to be tracked. The Company
				may obtain your device ID, which is sent to the Company’s servers and
				used in fraud prevention efforts.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					Information from Other Sources:
				</Text>{" "}
				We may obtain information, including Personal Information, from third
				parties and sources other than the ShopDeck, such as advertisers. We
				also may obtain information, including Personal Information, from third
				parties such as social media or social network platforms for which you
				have explicitly connected to the ShopDeck Service (e.g., information
				from YouTube using YouTube API Services, Twitch, Facebook, or any other
				platform the ShopDeck Service connects with). When you access the
				ShopDeck Service through social media or social networks you are
				authorizing ShopDeck to collect, store and use such information and
				content in accordance with this Policy.
			</Text>

			<Text styleguide="body1" color="gray">
				We may also obtain information from third parties such as social media
				or social network platforms regarding your use of such services,
				including information about your use of the content you choose to
				broadcast through the ShopDeck Service. Please refer to privacy policy
				of the social media or social network platforms you use with ShopDeck
				Service (for example to {""}
				<Link
					color="gray"
					relative
					underline
					to="http://www.google.com/policies/privacy"
					target="_blank"
				>
					Google Privacy Policy
				</Link>{" "}
				for YouTube). If we combine or associate information from other sources
				with Personal information that we collect through the ShopDeck Service,
				we will treat the combined information as Personal Information in
				accordance with this Policy. If you access your ShopDeck account through
				a third-party connection or log-in (like Google, Facebook), ShopDeck
				receives and stores information about your account from that service for
				authentication purposes. This information could include your email
				address, username, user ID associated with your account (for example,
				your Facebook UID), profile picture, and any other basic account info
				you've made publicly available. You should always review and adjust your
				privacy settings on third-party services you have permitted to access
				your account, like {""}
				<Link
					color="gray"
					relative
					underline
					to="https://myaccount.google.com/permissions"
					target="_blank"
				>
					Google
				</Link>{" "}
				and {""}
				<Link
					color="gray"
					relative
					underline
					to="https://www.facebook.com/settings?tab=applications"
					target="_blank"
				>
					Facebook.
				</Link>{" "}
				You may also revoke the access to individual services by visiting a
				corresponding third-party service page. For revoking google access visit{" "}
				{""}
				<Link
					color="gray"
					relative
					underline
					to="https://myaccount.google.com/permissions"
					target="_blank"
				>
					Google Permissions.
				</Link>{" "}
				If you unlink your third party account, we will no longer receive
				information collected about you in connection with that service, but
				you’ll be unable to access your ShopDeck account through that
				third-party service.
			</Text>

			<Text styleguide="body1" color="tertiary" underline fontWeight="bold">
				COOKIES
			</Text>

			<Text styleguide="body1" color="gray">
				The Company and its third-party service providers collect information
				from you by using cookies. A cookie is a small file stored on user's
				computer hard drive containing information about the user. The cookie
				helps the Company analyse web traffic or informs you about your use of a
				particular website. Cookies allow web applications to respond to you as
				an individual, tailoring its operations to your needs, likes and
				dislikes by gathering and remembering information about your
				preferences. When you visit ShopDeck, the Company may send one or more
				cookies (i.e., a small text file containing a string of alphanumeric
				characters) to your computer that identifies your browser. Some of these
				cookies may be connected to third-party companies or websites. The terms
				of use of such cookies are governed by this Policy and the privacy
				policy of the relevant third-party company or website. For example,
				Google measures the performance of advertisements by placing cookies on
				your computer when you click on ads. If you visit ShopDeck when you have
				such cookies on your computer, the Company and Google will be able to
				tell that you saw the ad delivered by Google. The terms of use of these
				cookies are governed by this Policy and Google's privacy policy.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					Disabling Cookies:
				</Text>
				You can choose to accept or decline cookies. Most web browsers
				automatically accept cookies, but you can usually modify your browser
				setting to decline cookies if you prefer. If you disable cookies, you
				may be prevented from taking full advantage of ShopDeck because it may
				not function properly if the ability to accept cookies is disabled.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" fontWeight="bold" color="tertiary">
					Clear GIFs, pixel tags and other technologies:
				</Text>
				Clear GIFs are tiny graphics with a unique identifier, similar in
				function to cookies. In contrast to cookies, which are stored on your
				computer's hard drive, clear GIFs are embedded invisibly on web pages.
				The Company may use clear GIFs (a.k.a. web beacons, web bugs or pixel
				tags), in connection with ShopDeck to, among other things, track the
				activities of ShopDeck visitors, help it manage content, and compile
				statistics about ShopDeck usage. The Company and its third-party service
				providers also use clear GIFs in HTML emails to the visitors, to help
				the Company track email response rates, identify when its emails are
				viewed, and track whether its emails are forwarded. We may also collect
				Usage Data, i.e. information that your browser sends whenever you visit
				our Services or when you access the Services by or through a mobile
				device.
			</Text>

			<Text styleguide="body1" color="tertiary" underline fontWeight="bold">
				HOW THE COMPANY USES THE INFORMATION IT COLLECTS
			</Text>

			<Text styleguide="body1" color="gray">
				The Company collects your Personal Information and aggregate information
				about the use of ShopDeck to operate, provide, develop, and improve the
				products and services that it offers to its customers and better
				understand your needs and to provide you with a better ShopDeck
				experience. Specifically, the Company may use your Personal Information
				for any of the following purposes (
			</Text>

			<Text styleguide="body1" color="gray">
				(
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					“Purposes"
				</Text>
				):
			</Text>

			<ol type="a" style={{ listStyleType: "none" }}>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(a) &nbsp; To provide our Services to you, including registering you
						for our Services , verifying your identity and authority to use our
						Services, and to otherwise enable you to use ShopDeck;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(b) &nbsp;To provide and maintain our Services;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(c) &nbsp;To notify you about changes to our Services;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(d) &nbsp;To allow you to participate in interactive features of our
						Service when you choose to do so;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(e) &nbsp;To provide customer support;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(f) &nbsp;To gather analysis or valuable information so that we can
						improve our Services;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(g) &nbsp;To monitor the usage of our Services;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(h) &nbsp;To detect, prevent and address technical issues;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(i) &nbsp;To provide you with news, special offers and general
						information about other goods, services and events which we offer
						that are similar to those that you have already purchased or
						enquired about unless you have opted not to receive such
						information;
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(j) &nbsp;To comply with legal obligations.
					</Text>
				</li>
			</ol>

			<Text styleguide="body1" color="gray">
				In certain cases, we collect and use your Personal Information to comply
				with laws. For instance, we collect information regarding place of
				establishment and bank account information for identity verification and
				other purposes.
			</Text>

			<Text styleguide="body1" color="gray">
				From time to time, the Company may use Personal Information for new and
				unanticipated uses not previously disclosed in its Policy. If the
				Company’s information practices change regarding information previously
				collected, the Company shall make reasonable efforts to provide notice
				and obtain consent of any such uses as may be required by law.
			</Text>

			<Text styleguide="body1" color="gray">
				By using the Platform and the Services, you agree and consent to the
				collection, transfer, use, storage, disclosure and sharing of your
				information as described and collected by the Company in accordance with
				this Policy. It is further clarified that your registration on ShopDeck
				shall be deemed to be your consent to be contacted for the purposes
				mentioned above, (i) on the mobile number shared by you even if you are
				registered with the National Customer Preference Register (NCPR) and
				have opted out of receiving promotional calls and messages and (ii) by
				way of SMS or email notifications or messages in any other electronic
				form. If you do not agree with the Policy, you may exercise your option
				to opt-out of the Policy by writing to the Company at {""}
				<Link
					color="gray"
					relative
					underline
					to="customercare@blitzscale.co"
					target="_blank"
				>
					customercare@blitzscale.co.
				</Link>{" "}
				The Company reserves the right to discontinue its Services to you in
				case you do not agree with any of the provisions of this Policy.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text
					as="span"
					styleguide="body1"
					fontWeight="bold"
					underline
					color="tertiary"
				>
					ELECTRONIC NEWSLETTERS, INVITATIONS, POLLS AND SURVEYS
				</Text>
				<br />
				At its sole discretion, the Company may offer any of the following free
				services through ShopDeck, which you may select to use or receive at
				your option. Some of the following services may require you to provide
				additional Personal Information as detailed below:
			</Text>
			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Electronic Newsletters:
				</Text>{" "}
				The Company may offer a free electronic newsletter to users. The Company
				will gather the email addresses of users who sign up on ShopDeck for the
				newsletter mailing list. Users may remove themselves from this mailing
				list by opting out of receiving newsletters during the registration
				process, by following the link provided in each newsletter that points
				users to a subscription management page where the user can unsubscribe
				from receiving newsletters or by changing their preferences in their
				profile settings page.
			</Text>
			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Send To a Friend:
				</Text>{" "}
				ShopDeck users can voluntarily choose to electronically forward a link,
				page, or document to someone else by clicking "send to a friend." To do
				so, the user must provide his or her email address, as well as the email
				address of the recipient. The user's email address is used only in the
				case of transmission errors and, of course, to let the recipient know
				who sent the email. The information is not used for any other purpose.
			</Text>
			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Polling:
				</Text>{" "}
				The Company may offer interactive polls to users so they can easily
				share their opinions with other users and see what its audience thinks
				about important issues, services, and/or ShopDeck. Opinions or other
				responses to polls are aggregated and are not identifiable to any
				particular user. The Company may use a system to "tag" users after they
				have voted, so they can vote only once on a particular question. This
				tag is not correlated with information about individual users.
			</Text>
			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Surveys:
				</Text>{" "}
				The Company may conduct user surveys from time to time to better target
				its content to the ShopDeck users. The Company will not share individual
				responses from these surveys with any third party. The Company will
				share aggregate data with third party service providers, partners, and
				other third parties. The Company also will post aggregate data
				containing survey responses on ShopDeck; that data may be viewed and
				downloaded by other users of ShopDeck.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text
					as="span"
					styleguide="body1"
					fontWeight="bold"
					underline
					color="tertiary"
				>
					RETENTION OF DATA
				</Text>
				<br />
				Company will retain your Personal Information only for as long as is
				necessary for the purposes set out in this Policy. We will retain and
				use your Personal Information to the extent necessary to comply with our
				legal obligations (for example, if we are required to retain your data
				to comply with applicable laws), resolve disputes and enforce our legal
				agreements and policies.
			</Text>
			<Text styleguide="body1" color="gray">
				Company will also retain Usage Data for internal analysis purposes.
				Usage Data is generally retained for a short period of time, except when
				this data is used to strengthen the security, i.e. to prevent fraud or
				future abuse, or to improve the functionality of our Services, or when
				we are legally obligated to retain this data for longer period of time.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text
					as="span"
					styleguide="body1"
					fontWeight="bold"
					underline
					color="tertiary"
				>
					TRANSFER OF DATA
				</Text>
				<Text styleguide="body1" color="gray">
					Your Personal Information may be transferred to and maintained on
					computers located outside of your state, province, country or other
					governmental jurisdiction where the data protection laws may differ
					from those of your jurisdiction. If you are located outside Karnataka,
					India and choose to provide information to us, please note that we
					transfer the data, including Personal Information, to Karnataka, India
					and process it there.
				</Text>
				<Text styleguide="body1" color="gray">
					Your consent to this Policy followed by your submission of such
					information represents your agreement to that transfer. The Company
					will take all the steps reasonably necessary to ensure that your data
					is treated securely and in accordance with this Policy and no transfer
					of your Personal Information will take place to an organisation or a
					country unless there are adequate controls in place including the
					security of your data and other Personal Information.
				</Text>
			</Text>

			<Text styleguide="body1" color="gray">
				<Text
					as="span"
					styleguide="body1"
					fontWeight="bold"
					underline
					color="tertiary"
				>
					DISCLOSURE
				</Text>
				<br />
				The Company may share the information that it collects about you,
				including your Personal Information, as follows:
			</Text>
			<Text styleguide="body1" color="gray">
				<Text styleguide="body1" color="tertiary" fontWeight="bold">
					Information Disclosed to Protect the Company and Others:
				</Text>
				<br />
				The Company may disclose your information including Personal Information
				if: (i) the Company reasonably believes that disclosure is necessary in
				order to comply with a legal process (such as a court order, search
				warrant, etc.) or another legal requirement of any governmental
				authority, (ii) disclosure would potentially mitigate its liability in
				an actual or potential lawsuit, (iii) reasonably necessary to enforce
				this Policy, its Terms of Use etc. (iv) disclosure is intended to help
				investigate or prevent unauthorized transactions or other illegal
				activities, or (v) necessary or appropriate to protect its rights or
				property, or the rights or property of any person or entity.
			</Text>
			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Information Disclosed to Third Party Service Providers and Business
					Partners:
				</Text>

				<Text styleguide="body1" color="gray">
					The Company may contract with various third parties for the provision
					and maintenance of ShopDeck and its business operations, and the
					Company may need to share your Personal Information and data generated
					by cookies and aggregate information (collectively,
					<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
						"information"
					</Text>
					) with these vendors and service agencies. For example, the Company
					may provide your information to a credit card processing company to
					process your payment. The vendors and service agencies will not
					receive any right to use your Personal Information beyond what is
					necessary to perform its obligations to provide the services to you.
					If you complete a survey, the Company also may share your information
					with the survey provider; if the Company offers a survey in
					conjunction with another entity, it also will disclose the results to
					that entity.
				</Text>
			</Text>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Information Disclosed to Law Enforcement or Government Officials under
					Applicable Law:
				</Text>
				<Text styleguide="body1" color="gray">
					The Company will disclose your information, including, without
					limitation, your name, city, state, telephone number, email address,
					user ID history, quoting and listing history, and complaints, to law
					enforcement or other government officials if it is required to do so
					by law, regulation or other government authority or otherwise in
					cooperation with an investigation of a governmental authority. Company
					may disclose your Personal Information in the good faith belief that
					such action is necessary to:
				</Text>
			</Text>

			<ol type="a" style={{ listStyleType: "none" }}>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(a) &nbsp; To comply with a legal obligation.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(b) &nbsp;To protect and defend the rights or property of Blitzscale
						Technology Solutions Private Limited.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(c) &nbsp;To prevent or investigate possible wrongdoing in
						connection with the Services.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(d) &nbsp;To protect the personal safety of users of the Services or
						the public.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(e) &nbsp;To protect against legal liability.
					</Text>
				</li>
			</ol>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					In the Event of a Change of Control or Bankruptcy:
				</Text>
				<Text styleguide="body1" color="gray">
					In the event that the Company undergoes a change in control,
					including, without limitation, a merger or sale of all or
					substantially all of the Company's assets to which ShopDeck relates or
					other corporate reorganization in which the Company participates, and
					is thus merged with or acquired by a third-party entity
				</Text>
				(
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					"Successor"
				</Text>
				), the Company hereby reserves the right to transfer the information it
				has collected from the users of ShopDeck to such Successor.
			</Text>

			<Text styleguide="body1" color="gray">
				In addition, in the event of the Company's bankruptcy, reorganization,
				receivership, or assignment for the benefit of creditors, or the
				application or laws or equitable principles affecting creditors' rights
				generally, the Company may not be able to control how your information
				is transferred, used, or treated and reserves the right to transfer the
				information the Company has collected from the users of ShopDeck to
				non-affiliated third parties in such event.
			</Text>

			<Text styleguide="body1" color="gray">
				<Text as="span" styleguide="body1" color="tertiary" fontWeight="bold">
					Information Disclosed at your Request:
				</Text>

				<Text styleguide="body1" color="gray">
					The Company may share your Personal Information with other Registered
					Users to whom you explicitly ask the Company to send your information
					or if you explicitly consent to such disclosure upon receipt of a
					specific service. For instance, when you contract for a specific good
					with another Registered User, the Company will send that Registered
					User a notice that includes the Personal Information that you have
					chosen to allow the Company to reveal to users with whom you contract.
				</Text>
			</Text>

			<Text styleguide="body1" color="tertiary" fontWeight="bold">
				Links to External Websites:
			</Text>
			<Text styleguide="body1" color="gray">
				ShopDeck may contain links to other websites or resources over which the
				Company does not have any control. Such links do not constitute an
				endorsement by the Company of those external websites. You acknowledge
				that the Company is providing these links to you only as a convenience,
				and further agree that the Company is not responsible for the content of
				such external websites. The Company is not responsible for the
				protection and privacy of any information which you provide while
				visiting such external websites and such sites are not governed by this
				Policy. Your use of any external website is subject to the terms of use
				and privacy policy located on the linked to external website.
			</Text>

			<Text styleguide="body1" color="tertiary" fontWeight="bold">
				Updating, Deleting and Correcting your Personal Information:
			</Text>
			<Text styleguide="body1" color="gray">
				You may choose to restrict the collection or use of your Personal
				Information in the following ways:
			</Text>

			<ol type="a" style={{ listStyleType: "none" }}>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(a) &nbsp; You can review, correct and delete your Personal
						Information by logging into ShopDeck.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(b) &nbsp;You must promptly update your Personal Information if it
						changes or is inaccurate. Typically, the Company will not manually
						alter your Personal Information because it is very difficult to
						verify your identity remotely. Nonetheless, upon your request the
						Company will close your Account and remove your Personal Information
						from view as soon as reasonably possible based on your Account
						activity and in accordance with applicable law.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(c) &nbsp;The Company does retain information from closed Accounts
						in order to comply with the law, prevent fraud, collect any fees
						owed, resolve disputes, troubleshoot problems, assist with any
						investigations of any Registered User, enforce its Terms of Use, and
						take any other actions otherwise permitted by law that it deems
						necessary in its sole and absolute discretion. You should
						understand, however, that once you publicly post any Feedback etc.,
						or any other information onto ShopDeck, you may not be able to
						change or remove it.
					</Text>
				</li>
				<li>
					<Text as="div" styleguide="body1" color="gray" mt="xl">
						(d) &nbsp;Once the Company has deleted or removed your Account, you
						agree that the Company shall not be responsible for any Personal
						Information that was not included within your deleted and/or removed
						Account that remains on ShopDeck.
					</Text>
				</li>
			</ol>

			<Text styleguide="body1" color="tertiary" fontWeight="bold" underline>
				SECURITY OF DATA
			</Text>

			<Text styleguide="body1" color="gray">
				The Company has designed its systems with your security and privacy in
				mind. The Company employs procedural and technological security
				measures, which are reasonably designed to help protect your Personal
				Information from unauthorized access or disclosure. The Company may use
				encryption, passwords, and physical security measures to help protect
				your Personal Information against unauthorized access and disclosure.
				The Company works to protect the security of your Personal Information
				during transmission by using encryption protocols and software. The
				Company maintains physical, electronic, and procedural safeguards in
				connection with the collection, storage, processing, and disclosure of
				personal customer information. The security procedures of the Company
				require it to occasionally request proof of identity before it discloses
				Personal Information to you. It is important for you to protect against
				unauthorized access to your password and to your computers, devices and
				applications. Be sure to sign off when finished using a shared computer.
				The Company is not responsible for the unauthorized use of your
				information or for any lost, stolen, compromised passwords, or for any
				activity on your Account via unauthorized password activity.
			</Text>

			<Text styleguide="body1" color="tertiary" fontWeight="bold" underline>
				WHAT CHOICES DO I HAVE REGARDING USE OF MY PERSONAL INFORMATION?
			</Text>

			<Text styleguide="body1" color="gray">
				The Company may send periodic promotional or informational emails to
				you. You may opt-out of such communications by following the opt-out
				instructions contained in the email. Please note that it may take up to
				10 business days for the Company to process opt-out requests. If you
				opt-out of receiving emails about recommendations or other information
				the Company thinks may interest you, it may still send you emails about
				your Account or any services you have requested or received from the
				Company.
			</Text>

			<Text styleguide="body1" color="tertiary" fontWeight="bold" underline>
				THIRD-PARTY AD NETWORKS
			</Text>

			<Text styleguide="body1" color="gray">
				The Company participates in third party ad networks that may display
				advertisements on other websites based on your visits to ShopDeck as
				well as other websites. This enables the Company and these third parties
				to target advertisements by displaying ads for products and services in
				which you might be interested. Third party ad network providers,
				advertisers, sponsors and/or traffic measurement services may use
				cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and
				other technologies to measure the effectiveness of their ads and to
				personalize advertising content to you. These third-party cookies and
				other technologies are governed by each third party's specific privacy
				policy, and not by this Policy. The Company may provide these
				third-party advertisers with information about your usage of ShopDeck
				and its Services, as well as aggregate or non-personally identifiable
				information about visitors to ShopDeck and users of ShopDeck.
			</Text>

			<Text styleguide="body1" color="tertiary" fontWeight="bold" underline>
				ANALYTICS
			</Text>

			<Text styleguide="body1" color="gray">
				We may use third-party service providers to monitor and analyze the use
				of our Services.
			</Text>

			<Text styleguide="body1" color="gray">
				Google Analytics: Google Analytics is a web analytics service offered by
				Google that tracks and reports website traffic. Google uses the data
				collected to track and monitor the use of our Services. This data is
				shared with other Google services. Google may use the collected data to
				contextualise and personalise the ads of its own advertising network.
				You can opt-out of having made your activity on the service available to
				Google Analytics by installing the Google Analytics opt-out browser
				add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
				analytics.js, and dc.js) from sharing information with Google Analytics
				about visits activity. For more information on the privacy practices of
				Google, please visit the Google Privacy & Terms web page: {""}
				<Link
					color="gray"
					underline
					relative
					to="https://policies.google.com/privacy?hl=en"
					target="_blank"
				>
					https://policies.google.com/privacy?hl=en.
				</Link>
			</Text>

			<Text
				styleguide="body1"
				color="tertiary"
				underline
				fontWeight="bold"
				underline
			>
				CHILDREN`S PRIVACY
			</Text>
			<Text styleguide="body1" color="gray">
				ShopDeck does not knowingly collect or solicit Personal Information from
				children under the age of 18 and the Services and its content are not
				directed at children under the age of 18. In the event that we learn
				that we have collected Personal Information from a child under age 18
				without verification of parental consent, we will delete that
				information as quickly as possible. If you believe that we might have
				any information from or about a child under 18. Please contact us at{" "}
				{""}
				<Link
					color="gray"
					underline
					relative
					to="rishabh@blitzscale.co"
					target="_blank"
				>
					rishabh@blitzscale.co.
				</Link>
			</Text>

			<Text
				styleguide="body1"
				color="tertiary"
				underline
				fontWeight="bold"
				underline
			>
				PRIVACY POLICY UPDATES
			</Text>
			<Text styleguide="body1" color="gray">
				This policy is current as of the effective as of the stated Last Update
				Date mentioned in the Policy. The Company may, in its sole and absolute
				discretion, change this Policy from time to time by updating this
				document. The Company will post its updated policy on ShopDeck on this
				page. The Company encourages you to review this Policy regularly for any
				changes. Your continued use of ShopDeck and/ or continued provision of
				Personal Information to the Company will be subject to the terms of the
				then-current Policy.
			</Text>
			<Text
				styleguide="body1"
				color="tertiary"
				underline
				fontWeight="bold"
				underline
			>
				GRIEVANCE OFFICER
			</Text>
			<Text styleguide="body1" color="gray">
				If you are a merchant, partner, user, ShopDeck employee, website visitor
				or other individual that ShopDeck has a direct relationship with and you
				are located in India, ShopDeck is the controller of your personal data.
				If you buy something from or otherwise provide your information to a
				ShopDeck-powered store, the relevant merchant is your data controller,
				and we are acting as a processor on their behalf. If you have questions
				about how a merchant or store processes your personal information, you
				should contact the merchant or visit their privacy policy.
			</Text>

			<Text styleguide="body1" color="gray">
				In accordance with Information Technology Act, 2000 and rules made there
				under, the name and contact details of the Grievance Officer are
				provided below:
			</Text>

				<Text styleguide="body1" color="tertiary" fontWeight="bold">
					Mr. Rishabh Verma
					<br />
					Blitzscale Technology Solutions Pvt Ltd.
					<br />
          Indiqube Alpha, Plot No. 19/4, 27, Outer Ring Road, Panathur Junction, Bengaluru, Karnataka 560103, India
					<br />
					Phone: +91 9869156273
					<br />
					Email: rishabh@blitzscale.co
					<br />
					Time: Mon – Sat (9:00 – 18:00)
				</Text>
			</div>
	);
};

export default Privacy;
