import React, { useLayoutEffect, useState } from "react";

import classNames from "classnames/bind";
import BookDemo from "../../atoms/BookDemo";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import Privacy from "../../molecules/Privacy";
import * as styles from "./PrivacyPolicy.module.scss";
import Loader from "../Loader";
import { fireDemoFormEvent } from "../../events/bookDemoForm";
import {useNavigate} from "react-router-dom"

const cx = classNames.bind(styles);

const PrivacyPolicy = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

  useLayoutEffect(() => setIsLoading(false), []);

  const handleBooking = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
		fireDemoFormEvent();
    navigate("/book-demo-form");   };

  if (isLoading) {
    return <Loader isLoading={isLoading} />
  }


  return (
    <main>
      <Navbar handleBooking={handleBooking} />

      <section className={cx("widget-wrapper-home")}>
        <Privacy />
      </section>
      <Footer />
      <BookDemo handleBooking={handleBooking} />
    </main>
  );
};

export default PrivacyPolicy;
