import React, { useLayoutEffect } from "react";
import "./OwlCarousel.scss";

const OwlCarousel = ({ children, owlSelector }) => {
  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      window.$(`.${owlSelector}.owl-carousel`).owlCarousel({
        loop: true,
        center: true,
        margin: 24,
        dotsEach: true,
        nav: true,
        autoplay: true,
        autoplayTimeout: 2500,
        responsive: {
          0: {
            items: 1,
            margin: 0,
            nav: false,
          },
          640: {
            items: 2,
            nav: false,
          },
          1200: {
            items: 3,
          },
        },
      });
    }
  }, []);

  return (
    <div className={`${owlSelector} owl-carousel owl-theme`}>{children}</div>
  );
};

export default OwlCarousel;
