import React, { useEffect, useRef, useState } from "react";
import Text from "../../atoms/Typography";
import classNames from "classnames/bind";
import * as style from "./Greetings.module.scss";
import Button from "../../atoms/Button";
import Flexbox from "../../atoms/Flexbox";
import { fireLinkClickedEvent } from "../../events/linkClickedEvent";

const cx = classNames.bind(style);

const Greetings = () => {
	// const [iframeMouseOver,SetiframeMouseOver] = useState(false)

	// useEffect(()=>{
	// 	window.focus();
	//   window.addEventListener("blur", onWindowBlur);
	// 	return () =>  window.removeEventListener("blur", onWindowBlur);
	// },[])

	// const onWindowBlur = () => {
	//   if (iframeMouseOver) {
	//     alert("IFRAME CLICKED");
	//   }
	// };

	// const handleOnMouseOver = () => {
	//  SetiframeMouseOver(true)
	// };

	// const handleOnMouseOut = () => {
	//   window.focus();
	//   SetiframeMouseOver(false)
	// };

	const handlePage = (ev: React.MouseEvent<HTMLElement>) => {
		ev.preventDefault();
		window.location.href = "/";
	};

	return (
		<section className={cx("widget")}>
			<div className={cx("parent-container")}>
				<Flexbox.Item className={cx("circle")}></Flexbox.Item>
			</div>
			<Text styleguide="heading2" styleguideSm="heading3-sm" align="center" mb="md">
				<Text styleguide="heading2" styleguideSm="heading3-sm" color="primary" as="span">Thank you for your interest!</Text>
			</Text>
			<div>
				<Text styleguide="body1" styleguideSm="body2-sm" align="center" mb="xl" color="gray">
					Shopdeck consultants will be contacting you within 24-48 hours for
					your FREE demo session.
				</Text>
			</div>
			<Text styleguide="heading2" styleguideSm="heading3-sm" align="center" mb="md">Meanwhile get to know more about <br />
				how
				<Text styleguide="heading2" styleguideSm="heading3-sm" color="primary" as="span"> Shopdeck</Text> can help you here
			</Text>
			<Flexbox mt="xl" direction="row" justify="center">
				<iframe
					width="605" //fit full modal screen
					height="332.3" //fit full modal screen
					src="https://www.youtube.com/embed/L4iNQwpTf1k"
					title="YouTube video player"
					allow="autoplay; encrypted-media; picture-in-picture"
				/>
			</Flexbox>
			<Flexbox mt="xxl" direction="row" justify="center">
				<Button appearance="tertiary" size="md" className={cx("button")} onClick={handlePage}>
					Back to Home
				</Button>
			</Flexbox>
		</section>
	)

}

export default Greetings
