// extracted by mini-css-extract-plugin
var _1 = "Profitability-module__graph-icon___uU8vr";
var _2 = "Profitability-module__hand-icon___obX7d";
var _3 = "Profitability-module__line-graph-icon___Gsc9C";
var _4 = "Profitability-module__profitability-content-container___B4PJD";
var _5 = "Profitability-module__profitability-feature-container____pUf6";
var _6 = "Profitability-module__profitability-feature-container-background___vkggW";
var _7 = "Profitability-module__profitability-feature-container-item___sosua";
var _8 = "Profitability-module__widget-profitability___LRw3m";
export { _1 as "graph-icon", _2 as "hand-icon", _3 as "line-graph-icon", _4 as "profitability-content-container", _5 as "profitability-feature-container", _6 as "profitability-feature-container-background", _7 as "profitability-feature-container-item", _8 as "widget-profitability" }
