import React from "react";
import { FlexboxGrid } from "rsuite";
import { FlexboxComponentProps } from "./types";
import classNames from "classnames/bind";
import * as style from "./Flexbox.module.scss";
const cx = classNames.bind(style);

const Flexbox = (props: FlexboxComponentProps): JSX.Element => {
  const {
    children,
    direction,
    nowrap,
    className,
    mt,
    mb,
    align,
    gutter,
    ...rest
  } = props;
  return (
    <FlexboxGrid
      className={cx(
        `flex-${direction}`,
        `${align ? `align-${align}` : ""}`,
        `${mt ? `mt-${mt}` : ""}`,
        `${mb ? `mb-${mb}` : ""}`,
        `${nowrap ? "nowrap" : ""}`,
        `${gutter ? `gutter-${gutter}` : ""}`,
        className
      )}
      {...rest}
    >
      {children}
    </FlexboxGrid>
  );
};

Flexbox.Item = FlexboxGrid.Item;

export default Flexbox;
